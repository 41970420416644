import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { StarIcon } from '@heroicons/react/outline';
import axios from 'axios';

const PastWinners = () => {
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    fetchWinners();
    const intervalId = setInterval(fetchWinners, 5000); // Fetch new winners every minute
    return () => clearInterval(intervalId);
  }, []);

  const fetchWinners = async () => {
    try {
      const response = await axios.get('https://home.homeaidrelief.com/api/generate_winners.php');
      localStorage.setItem('winners', JSON.stringify(response.data));
      setWinners(response.data);
    } catch (error) {
      console.error('Error fetching winners:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-blue-50 to-gray-50">
      <Navbar />
      <div className="flex-1 flex flex-col items-center justify-center px-4 py-8">
        <div className="bg-white shadow-2xl rounded-lg p-4 w-full max-w-4xl mb-8">
          <div className="text-center mb-4" data-aos="fade-up">
            <StarIcon className="w-16 h-16 text-yellow-500 mx-auto mb-2" />
            <h1 className="text-4xl font-extrabold text-gray-800">Past Winners</h1>
            <p className="text-sm text-gray-600 leading-relaxed mt-2">
              Celebrate our lucky winners and get inspired by their success stories. Note that some images of past winners have been concealed due to privacy and security reasons.
            </p>
          </div>
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            interval={5000}
            transitionTime={1000}
          >
            {winners.map((winner, index) => (
              <div key={index} className="p-4 flex flex-col items-center" data-aos="fade-up" data-aos-delay={200 * index}>
                <div className="relative">
                  <img
                    src={winner.image}
                    alt={winner.name}
                    className="rounded-full mb-4 border-4 border-blue-500 w-32 h-32 object-cover winner-img"
                  />
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-green-500 opacity-0 winner-overlay"></div>
                </div>
                <h2 className="text-xl font-bold text-gray-800">{winner.name}</h2>
                <p className="text-gray-600">Won: {winner.amount}</p>
                <p className="text-gray-600">Date: {winner.date}</p>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <Footer className="mt-auto" />
    </div>
  );
};

export default PastWinners;
