import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardNavbar from '../components/DashboardNavbar';
import BottomNav from '../components/BottomNav';
import DashboardHome from './DashboardHome';
import Wallet from './Wallet';
import Settings from './Settings';
import Notifications from './Notifications';
import Messages from './Messages';
import Profile from './Profile';
import BuyNewTickets from './BuyNewTickets';
import CheckTickets from './CheckTickets';
import PlayTickets from './PlayTickets';
import WithdrawalConfirmation from '../components/wallet/WithdrawalConfirmation';
import TransactionHistory from './TransactionHistory';
import { AuthContext } from '../context/AuthProvider';
import axios from 'axios';
import WonTicketModal from '../components/tickets/WonTicketModal';
import NewsModal from '../components/News/NewsModal';
import '../assets/styles/animations.css';

const Dashboard = () => {
  const { currentUser } = useContext(AuthContext);
  const [winningTickets, setWinningTickets] = useState([]);
  const [showWonTicketModal, setShowWonTicketModal] = useState(false);
  const [news, setNews] = useState(null);
  const [isNewsModalOpen, setIsNewsModalOpen] = useState(false);
  
  useEffect(() => {
    const fetchWinningTickets = async () => {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/tickets/check_won_tickets.php', {
          uid: currentUser.uid,
        });

        if (response.data.status === 'success' && response.data.tickets.length > 0) {
          const displayedTickets = new Set(JSON.parse(localStorage.getItem('displayedTickets')) || []);
          const newTickets = response.data.tickets.filter(ticket => !displayedTickets.has(ticket.ticket_number));
          
          if (newTickets.length > 0) {
            setWinningTickets(newTickets);
            setShowWonTicketModal(true);
            newTickets.forEach(ticket => displayedTickets.add(ticket.ticket_number));
            localStorage.setItem('displayedTickets', JSON.stringify([...displayedTickets]));
          }
        }
      } catch (error) {
        console.error('Error fetching winning tickets:', error);
      }
    };

    fetchWinningTickets(); // Initial fetch
    const intervalId = setInterval(fetchWinningTickets, 5000); // Fetch every 5 seconds

    return () => clearInterval(intervalId);
  }, [currentUser.uid]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/news/news.php', {
          uid: currentUser.uid,
        });
        
        const latestNews = response.data.news;
        if (latestNews && latestNews.length > 0) {
          setNews(latestNews[0]);
          setIsNewsModalOpen(true); // Auto trigger modal if there's new news
        }
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews(); // Initial fetch
    const intervalId = setInterval(fetchNews, 5000); // Fetch every 5 seconds

    return () => clearInterval(intervalId);
  }, [currentUser.uid]);

  const handleNewsModalClose = async () => {
    if (news) {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/news/mark_as_read.php', {
          firebase_uid: currentUser.uid, // Send firebase_uid instead of user_id
          news_id: news.id
        });
        
        if (response.data.success) {
          setIsNewsModalOpen(false);
          setNews(null);
        } else {
          console.error('Error marking news as read:', response.data.error);
        }
      } catch (error) {
        console.error('Error marking news as read:', error);
      }
    }
  };

  const closeWonTicketModal = () => {
    setShowWonTicketModal(false);
    setWinningTickets([]);
  };

  return (
    <div className="min-h-screen flex flex-col relative overflow-hidden animated-bg">
      <DashboardNavbar />
      <div className="flex flex-1 flex-col items-center justify-center px-4 py-8 w-full">
        <Routes>
          <Route path="/home" element={<DashboardHome />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/buy-new-tickets" element={<BuyNewTickets />} />
          <Route path="/play-tickets" element={<PlayTickets />} />
          <Route path="/check-tickets" element={<CheckTickets />} />
          <Route path="/withdraw-confirmation" element={<WithdrawalConfirmation />} />
          <Route path="/transaction-history" element={<TransactionHistory />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>
      <BottomNav />
      {winningTickets.length > 0 && (
        <WonTicketModal
          isOpen={showWonTicketModal}
          onClose={closeWonTicketModal}
          ticket={winningTickets[0]}
        />
      )}
      {news && (
        <NewsModal
          isOpen={isNewsModalOpen}
          onClose={handleNewsModalClose}
          news={news}
        />
      )}
    </div>
  );
};

export default Dashboard;
