import React, { useState, useEffect } from 'react';
import { CashIcon, CalendarIcon } from '@heroicons/react/outline';
import DatePicker from 'react-datepicker';
import CustomModal from '../CustomModal'; // Assuming you have a modal component

const WithdrawFunds = ({
  accountInfo,
  withdrawAmount,
  setWithdrawAmount,
  balance,
  withdrawDate,
  setWithdrawDate,
  handleWithdraw,
  selectedOption,
  setSelectedOption,
}) => {
  const [rawInput, setRawInput] = useState(withdrawAmount);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption('check'); // Set default to 'check'
    }
  }, [selectedOption, setSelectedOption]);

  const formatAmount = (amount) => {
    const number = parseFloat(amount.replace(/,/g, ''));
    if (isNaN(number)) return '';
    return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    if (!isNaN(value) || value === '') {
      setRawInput(value);
      setWithdrawAmount(value);
    }
  };

  const handleInputBlur = () => {
    setRawInput(formatAmount(withdrawAmount));
  };

  const handleInputFocus = () => {
    setRawInput(withdrawAmount);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const confirmWithdraw = () => {
    handleWithdraw();
    handleModalClose();
  };

  return (
    <section className="mb-6">
      <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
        {accountInfo && (
          <>
            <h3 className="text-xl font-bold text-white flex items-center mb-4">
              <CashIcon className="w-6 h-6 mr-2" />
              Withdraw Funds
            </h3>
            <div className="flex items-center space-x-4 mb-4">
              <input
                type="text"
                value={rawInput}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
                placeholder="Enter amount"
                className="w-full p-3 border rounded-md bg-gray-800 text-white"
              />
              <button
                onClick={() => {
                  const formattedBalance = formatAmount(balance.toString());
                  setRawInput(formattedBalance);
                  setWithdrawAmount(balance.toString());
                }}
                className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
              >
                <CashIcon className="w-5 h-5 mr-2" />
                Max
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-gray-200">Select Date</label>
              <div className="flex items-center">
                <DatePicker
                  selected={withdrawDate}
                  onChange={(date) => setWithdrawDate(date)}
                  className="w-full p-3 border rounded-md bg-gray-800 text-white"
                />
                <CalendarIcon className="w-6 h-6 ml-2 text-gray-200" />
              </div>
            </div>
            <button
              onClick={handleModalOpen}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              <CashIcon className="w-5 h-5 mr-2" />
              Withdraw Now
            </button>
          </>
        )}
      </div>

      <CustomModal isOpen={isModalOpen} onClose={handleModalClose}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Confirm Withdrawal</h3>
          <p className="mb-4">Are you sure you want to withdraw <strong>${formatAmount(withdrawAmount)}</strong>?</p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={handleModalClose}
              className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Cancel
            </button>
            <button
              onClick={confirmWithdraw}
              className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Confirm
            </button>
          </div>
        </div>
      </CustomModal>
    </section>
  );
};

export default WithdrawFunds;
