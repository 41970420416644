// src/components/tickets/TicketOption.js
import React, { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import CustomModal from '../CustomModal'; // Assuming you have a modal component

const TicketOption = ({ ticket, onBuyClick }) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const toggleInfoModal = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  return (
    <div className="bg-gray-700 p-4 rounded-lg shadow-md flex items-center justify-between">
      <div className="flex items-center">
        <h3 className="text-xl font-bold text-white">${ticket.price}</h3>
        <button onClick={toggleInfoModal} className="ml-2 p-1 rounded-full bg-gray-600 hover:bg-gray-500 transition duration-300">
          <InformationCircleIcon className="w-6 h-6 text-white" />
        </button>
      </div>
      <button
        onClick={onBuyClick}
        className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg flex items-center text-sm shadow-md transition transform hover:scale-105"
      >
        Buy
      </button>
      <CustomModal isOpen={isInfoOpen} onClose={toggleInfoModal}>
        <h2 className="text-xl font-bold mb-4">Ticket Information</h2>
        <p>{ticket.detail}</p>
        <button
          onClick={toggleInfoModal}
          className="mt-4 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105"
        >
          Close
        </button>
      </CustomModal>
    </div>
  );
};

export default TicketOption;
