// src/components/Dashboard/LotteryInfoModal.js
import React from 'react';
import CustomModal from '../CustomModal';
import { TicketIcon, CalendarIcon, CheckCircleIcon, SearchIcon, PlayIcon, ShoppingCartIcon } from '@heroicons/react/solid';

const LotteryInfoModal = ({ isOpen, onClose }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-2xl font-bold mb-6">Lottery Tickets Information</h2>

      <div className="mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <TicketIcon className="w-6 h-6 mr-2 text-yellow-500" />
          Ticket Number
        </h3>
        <p>
          Each ticket has a unique number that is entered into the draw.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <CalendarIcon className="w-6 h-6 mr-2 text-green-500" />
          Draw Date
        </h3>
        <p>
          The date on which the draw will take place.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <CheckCircleIcon className="w-6 h-6 mr-2 text-blue-500" />
          Status
        </h3>
        <p>
          Indicates whether the ticket has won or lost. A winning ticket will be marked as 'Won'.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <SearchIcon className="w-6 h-6 mr-2 text-indigo-500" />
          Check Tickets
        </h3>
        <p>
          You can check the status of your tickets to see if you have won any prizes.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <PlayIcon className="w-6 h-6 mr-2 text-red-500" />
          Play Tickets
        </h3>
        <p>
          Participate in new draws by playing your tickets.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <ShoppingCartIcon className="w-6 h-6 mr-2 text-purple-500" />
          Buy New Tickets
        </h3>
        <p>
          Purchase additional tickets to increase your chances of winning.
        </p>
      </div>

      <button
        onClick={onClose}
        className="mt-6 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105"
      >
        Close
      </button>
    </CustomModal>
  );
};

export default LotteryInfoModal;
