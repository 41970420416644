import React, { useState, useContext, useEffect, useCallback } from 'react';
import { AuthContext } from '../context/AuthProvider';
import axios from 'axios';
import AvailableBalance from '../components/wallet/AvailableBalance';
import WithdrawalOptions from '../components/wallet/WithdrawalOptions';
import WithdrawFunds from '../components/wallet/WithdrawFunds';
import WithdrawalHistory from '../components/wallet/WithdrawalHistory';
import PaymentLoader from '../components/PaymentLoader';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Wallet = () => {
  const { currentUser, userData } = useContext(AuthContext); // Added userData
  const [balance, setBalance] = useState(0);
  const [accountInfo, setAccountInfo] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawDate, setWithdrawDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [selectedOption, setSelectedOption] = useState(''); // Default type to ''
  const [bankDetails, setBankDetails] = useState({
    bankName: '',
    accountHolder: '',
    accountNumber: '',
    routineNumber: '',
    bankAddress: '',
  });

  const [checkDetails, setCheckDetails] = useState({
    address: '',
    city: '',
    state: '',
    zip: '',
  });

  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.post('https://home.homeaidrelief.com/api/user.php', {
        action: 'getUserData',
        uid: currentUser.uid,
      });
  
      const userData = response.data;
      setBalance(parseFloat(userData.balance) || 0);
      setAccountInfo(userData.accountInfo || {});
      setBankDetails(userData.bank_details || {
        bankName: '',
        accountHolder: '',
        accountNumber: '',
        routineNumber: '',
        bankAddress: '',
      });
      setCheckDetails({
        address: userData.address || '',
        city: userData.city || '',
        state: userData.state || '',
        zip: userData.zip || '',
      });
  
      // Ensure currentUser.id is set
      currentUser.id = userData.id;
  
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [currentUser]);

  const fetchWithdrawalHistory = useCallback(async () => {
    try {
      const response = await axios.post('https://home.homeaidrelief.com/api/user.php', {
        action: 'getWithdrawalHistory',
        user_id: currentUser.id,
      });

      if (response.data.status === 'success') {
        if (response.data.history && response.data.history.length > 0) {
          setWithdrawalHistory(response.data.history);
        } else {
          setWithdrawalHistory([]); // Set empty array if no history exists
        }
      } else {
        // Handle other error status if needed
      }
    } catch (error) {
      // Handle fetch error if needed
    }
  }, [currentUser.id]);

  useEffect(() => {
    fetchUserData();
    fetchWithdrawalHistory();
  }, [fetchUserData, fetchWithdrawalHistory]);

  const handleAddAccount = async (e) => {
    e.preventDefault();
    const { bankName, accountHolder, accountNumber, routineNumber, bankAddress } = bankDetails;

    try {
      await axios.post('https://home.homeaidrelief.com/api/user.php', {
        action: 'addBankDetails',
        uid: currentUser.uid,
        bankName,
        accountHolder,
        accountNumber,
        routineNumber,
        bankAddress,
      });

      setSelectedOption('bankDeposit'); // Set selected option
      toast.success('Bank details added successfully.');
    } catch (error) {
      console.error('Error adding bank details:', error);
      toast.error('Failed to add bank details.');
    }
  };

  const handleAddCheckDetails = async (e) => {
    e.preventDefault();
    const { address, city, state, zip } = checkDetails;

    try {
      await axios.post('https://home.homeaidrelief.com/api/user.php', {
        action: 'addCheckDetails',
        uid: currentUser.uid,
        address,
        city,
        state,
        zip,
      });

      setSelectedOption('check'); // Set selected option
      toast.success('Check details added successfully.');
    } catch (error) {
      console.error('Error adding check details:', error);
      toast.error('Failed to add check details.');
    }
  };

  const handleWithdraw = async () => {
    if (userData.status !== 'Active') {
      toast.error('Your account is not active. You cannot withdraw funds.');
      return;
    }
    if (withdrawAmount < 500) {
      toast.error('The minimum withdrawal amount is $500.');
      return;
    }
    if (withdrawAmount > balance) {
      toast.error('Insufficient balance');
      return;
    }
  
    setIsLoading(true);
    const postData = {
      user_id: currentUser.id,
      type: selectedOption, // Ensure this is set correctly based on user selection
      amount: withdrawAmount,
      bank_name: bankDetails.bankName,
      account_number: bankDetails.accountNumber,
      address: checkDetails.address,
      city: checkDetails.city,
      state: checkDetails.state,
      zip: checkDetails.zip,
    };
    
    setTimeout(async () => {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/withdraw/withdraw.php', {
          uid: currentUser.uid,
          type: selectedOption, // Use selectedOption here
          amount: withdrawAmount,
          bank_name: bankDetails.bankName,
          account_number: bankDetails.accountNumber,
          address: checkDetails.address,
          city: checkDetails.city,
          state: checkDetails.state,
          zip: checkDetails.zip,
        });
    
        
        if (response.data.status === 'success') {
          // Update balance and history in the backend
          const updatedHistory = [...withdrawalHistory, {
            ...postData,
            date: new Date().toLocaleString(),
          }];
  
          
          
          await axios.post('https://home.homeaidrelief.com/api/user.php', {
            action: 'updateBalanceAndHistory',
            uid: currentUser.uid,
            balance: balance - withdrawAmount,
            withdrawalHistory: updatedHistory,
          });
  
          // Fetch the updated user data to ensure balance is correctly updated
          await fetchUserData();
          await fetchWithdrawalHistory();
  
          setIsLoading(false);
  
          // Navigate to WithdrawalConfirmation with state
          navigate('/dashboard/withdraw-confirmation', {
            state: {
              type: selectedOption,
              withdrawAmount,
              withdrawDate,
              ...(selectedOption === 'bankDeposit' ? bankDetails : checkDetails)
            }
          });
  
          setWithdrawAmount('');
        } else {
          toast.error(response.data.message || 'Failed to process withdrawal.');
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error logging withdrawal:', error);
        toast.error('Failed to process withdrawal.');
        setIsLoading(false);
      }
    }, 5000);
  };

  return (
    <div className="container mx-auto p-6 mt-12">
      <AvailableBalance balance={balance} />
      
      <WithdrawalOptions
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        handleAddAccount={handleAddAccount}
        handleAddCheckDetails={handleAddCheckDetails}
        bankDetails={bankDetails}
        setBankDetails={setBankDetails}
        checkDetails={checkDetails}
        setCheckDetails={setCheckDetails}
      />
      <WithdrawFunds
        accountInfo={accountInfo}
        withdrawAmount={withdrawAmount}
        setWithdrawAmount={setWithdrawAmount}
        balance={balance}
        withdrawDate={withdrawDate}
        setWithdrawDate={setWithdrawDate}
        handleWithdraw={handleWithdraw}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      {isLoading && <PaymentLoader />}
      {withdrawalHistory.length > 0 && <WithdrawalHistory withdrawalHistory={withdrawalHistory} />}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default Wallet;
