// src/components/Dashboard/AnnouncementContainer.js
import React, { useState } from 'react';
import { FaBullhorn, FaInfoCircle } from 'react-icons/fa';
import CustomModal from '../CustomModal'; // Import the CustomModal component
import './AnnouncementContainer.css';

const AnnouncementContainer = ({ announcements }) => {
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAnnouncementClick = (announcement) => {
    setSelectedAnnouncement(announcement);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAnnouncement(null);
  };

  return (
    <>
      <div className="announcement-container rounded-lg shadow-md">
        <div className="announcement-icon">
          <FaBullhorn className="icon" />
        </div>
        <div className="announcement-marquee-container">
          <div className="announcement-marquee">
            {announcements.map((announcement) => (
              <span 
                key={announcement.id} 
                className="announcement-text"
                onClick={() => handleAnnouncementClick(announcement)}
              >
                {announcement.message}
              </span>
            ))}
          </div>
        </div>
      </div>

      <CustomModal isOpen={isModalOpen} onClose={closeModal}>
        <div className="p-6 bg-gray-900 text-white rounded-lg text-center">
          <FaInfoCircle className="modal-icon text-4xl mb-4 text-yellow-500" />
          <div className="modal-body mt-2">
            <p className="announcement-modal-message">{selectedAnnouncement?.message}</p>
          </div>
          <div className="modal-footer mt-6 flex justify-center">
            <button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-6 rounded-lg" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default AnnouncementContainer;
