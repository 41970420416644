import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { LockClosedIcon, KeyIcon } from '@heroicons/react/outline';
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import CustomModal from '../CustomModal'; // Assuming you have a modal component

const ChangePassword = () => {
  const { currentUser } = useContext(AuthContext);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const auth = getAuth();
    const credential = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );

    try {
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, newPassword);
      setModalMessage('Password changed successfully');
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error changing password:', error);
      setError('Failed to change password. Please make sure your current password is correct.');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalMessage('');
  };

  return (
    <div>
      <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
        <LockClosedIcon className="w-6 h-6 mr-2" />
        Change Password
      </h3>
      <div className="space-y-4">
        <div>
          <label className="block text-gray-300">Current Password</label>
          <input
            type="password"
            className="w-full p-3 border rounded-md bg-gray-800 text-gray-200"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-gray-300">New Password</label>
          <input
            type="password"
            className="w-full p-3 border rounded-md bg-gray-800 text-gray-200"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-gray-300">Confirm New Password</label>
          <input
            type="password"
            className="w-full p-3 border rounded-md bg-gray-800 text-gray-200"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <button
          onClick={handleChangePassword}
          className="bg-blue-600 hover:bg-blue-700 text-white p-3 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
        >
          <KeyIcon className="w-5 h-5 mr-2" />
          Change Password
        </button>
      </div>
      <CustomModal isOpen={isModalOpen} onClose={closeModal}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Password Change</h3>
          <p>{modalMessage}</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={closeModal}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default ChangePassword;
