// src/pages/Home.js
import React from 'react';
import Navbar from '../components/Navbar';
import HeroSlider from '../components/HeroSlider';
import '../styles/animations.css'; // Import the CSS file for animations
import Footer from '../components/Footer';


const Home = () => {
  return (
    <div className="bg-background min-h-screen flex flex-col">
      <Navbar />
      <header className="relative fade-in"> {/* Add fade-in class here */}
        <HeroSlider />
        <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center text-white p-4">
          <h2 className="text-4xl font-bold">Transform Your Dreams into Reality</h2>
          <p className="mt-4 max-w-xl">Imagine the possibilities that await with each ticket. Every play brings you closer to the life you've always dreamed of.</p>
        </div>
      </header>
      <main className="flex-1 p-4">
        <section className="text-center mb-8">
          <h2 className="text-2xl font-semibold text-text">Welcome to Golden Lottery</h2>
          <p className="text-lg text-text mt-2">Your chance to win big every week!</p>
        </section>

        <section className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          <div className="p-4 bg-white shadow-md rounded-lg">
            <h3 className="text-xl font-bold text-accent">Check Your Claim</h3>
            <p className="text-text mt-2">See if you are a lucky winner today.</p>
            <p className="text-lg text-text mt-2">Every journey begins with a single step. Take yours now by joining our lotto. Where it leads could be extraordinary.</p>
            <a href="/check-claim" className="mt-4 inline-block px-6 py-3 bg-accent text-white rounded hover:bg-secondary">Check Claim</a>
          </div>
          <div className="p-4 bg-white shadow-md rounded-lg">
  <h3 className="text-xl font-bold text-accent">Past Winners</h3>
  <p className="text-text mt-2">Explore the stories of our past winners.</p>
  <a href="/past-winners" className="inline-block mt-4 px-4 py-2 bg-accent text-white rounded hover:bg-secondary">
    View Past Winners
  </a>
</div>

        </section>

        <section className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          <div className="p-4 bg-white shadow-md rounded-lg">
            <h3 className="text-xl font-bold text-accent">How to Play</h3>
            <p className="text-text mt-2">Learn the rules and increase your chances of winning.</p>
          </div>
          <div className="p-4 bg-white shadow-md rounded-lg">
            <h3 className="text-xl font-bold text-accent">Upcoming Draws</h3>
            <p className="text-text mt-2">Don't miss out on our upcoming draws and events.</p>
          </div>
        </section>
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
  <div className="box p-6 bg-white shadow-md rounded-lg">
    <h3 className="text-xl font-bold text-accent">Dream Big</h3>
    <p className="text-text mt-2">With life-changing jackpots, your biggest dreams are within reach. It's more than a game; it's your future on the line.</p>
  </div>
  <div className="box p-6 bg-white shadow-md rounded-lg">
    <h3 className="text-xl font-bold text-accent">Trusted and Secure</h3>
    <p className="text-text mt-2">Experience a lotto system built on trust and security. Your peace of mind is our priority.</p>
  </div>
  <div className="box p-6 bg-white shadow-md rounded-lg">
    <h3 className="text-xl font-bold text-accent">Instant Wins</h3>
    <p className="text-text mt-2">Why wait? Discover instant win games and get immediate results. Your next play could be the big one.</p>
  </div>
  <div className="box p-6 bg-white shadow-md rounded-lg">
    <h3 className="text-xl font-bold text-accent">Community Impact</h3>
    <p className="text-text mt-2">Every ticket purchased contributes to community projects. Win big while making a difference.</p>
  </div>
</section>


        <section className="mt-8 text-center">
          <h2 className="text-2xl font-bold text-text">Meet Our Winners</h2>
          <p className="text-lg text-text mt-2">Join the ranks of those who dared to dream. Your picture could be here, alongside our happiest winners.</p>
          <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-4">
            <img src="https://cdn.midjourney.com/8a9f8d72-b39c-4e17-904f-b14f68782f5e/0_2.webp" alt="Winner 1" className="rounded shadow-md" />
            <img src="https://cdn.midjourney.com/da58280d-6aa6-4bff-9419-b781c5b43021/0_3.webp" alt="Winner 2" className="rounded shadow-md" />
            <img src="https://cdn.midjourney.com/9ae4689d-ab9c-4a4e-86b1-a3fb4b2b0837/0_2.webp" alt="Winner 3" className="rounded shadow-md" />
            <img src="https://cdn.midjourney.com/34e2ca94-c7e9-4743-bdaf-2def96a75c56/0_2.webp" alt="Winner 4" className="rounded shadow-md" />
          </div>
        </section>
      </main>
      <footer className="bg-primary p-4 text-center">
      <Footer />
      </footer>
    </div>
  );
};

export default Home;
