// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './assets/styles/tailwind.css';
import './index.css';
import App from './App';
import FirebaseContextProvider from './context/FirebaseContextProvider';
import AuthProvider from './context/AuthProvider';
import 'animate.css/animate.min.css';


// Override console.error to suppress specific warning
const originalConsoleError = console.error;
console.error = function (message, ...args) {
  if (message.includes('Support for defaultProps will be removed from function components in a future major release')) {
    return;
  }
  originalConsoleError.apply(console, [message, ...args]);
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <FirebaseContextProvider>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </FirebaseContextProvider>
  </React.StrictMode>
);
