import React, { createContext, useEffect, useState } from 'react';
import { auth } from '../firebase-config';
import axios from 'axios';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user) {
        try {
          const response = await axios.post('https://home.homeaidrelief.com/api/user.php', {
            action: 'getUserData',
            uid: user.uid,
          });

          if (response.data) {
            setUserData(response.data);
          }
        } catch (error) {
          console.warn('Error fetching user data');
        }
      } else {
        setUserData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, loading, userData }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
