import React, { useState } from 'react';
import CustomModal from '../CustomModal';
import { InformationCircleIcon } from '@heroicons/react/outline';
import Terms from '../SettingsNavs/Terms';
import Privacy from '../SettingsNavs/Privacy';

const AboutSettings = () => {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
        <InformationCircleIcon className="w-6 h-6 mr-2" />
        About
      </h3>
      <div className="space-y-6">
        <div>
          <label className="block text-gray-400 mb-2">Application Version</label>
          <p className="p-3 border rounded-md bg-gray-800 text-gray-200">2.2.0 Stable</p>
        </div>
        <div>
          <label className="block text-gray-400 mb-2">Terms of Service</label>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md flex justify-center items-center text-sm shadow-md transition transform hover:scale-105"
            onClick={() => setIsTermsModalOpen(true)}
          >
            View
          </button>
        </div>
        <div>
          <label className="block text-gray-400 mb-2">Privacy Policy</label>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md flex justify-center items-center text-sm shadow-md transition transform hover:scale-105"
            onClick={() => setIsPrivacyModalOpen(true)}
          >
            View
          </button>
        </div>
      </div>

      <CustomModal isOpen={isTermsModalOpen} onClose={() => setIsTermsModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md max-h-96 overflow-y-auto">
          <Terms />
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsTermsModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isOpen={isPrivacyModalOpen} onClose={() => setIsPrivacyModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md max-h-96 overflow-y-auto">
          <Privacy />
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsPrivacyModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default AboutSettings;
