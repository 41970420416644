// components/tickets/TicketBalance.js
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthProvider';
import FundTicketBalanceModal from './FundTicketBalanceModal';
import { EyeIcon, EyeOffIcon, CalendarIcon, ExclamationIcon, CheckCircleIcon, RefreshIcon, ClipboardListIcon } from '@heroicons/react/outline';
import { FaWallet } from 'react-icons/fa';
import CustomModal from '../CustomModal';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const TicketBalance = ({ onBalanceChange }) => {
  const { currentUser, userData } = useContext(AuthContext); // Use userData instead of currentUser directly
  const [balance, setBalance] = useState(0);
  const [lastTransaction, setLastTransaction] = useState('N/A');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const navigate = useNavigate();

  const fetchBalance = useCallback(async () => {
    try {
      const response = await axios.post('https://home.homeaidrelief.com/api/tickets/fetch_balance.php', {
        uid: currentUser.uid,
      });
      if (response.data.status === 'success') {
        setBalance(response.data.balance);
      } else {
        setAlert({ show: true, message: response.data.message, type: 'error' });
      }
    } catch (error) {
      setAlert({ show: true, message: 'Error fetching balance', type: 'error' });
    }
  }, [currentUser]);

  const fetchLastTransaction = useCallback(async () => {
    try {
      const response = await axios.post('https://home.homeaidrelief.com/api/tickets/last_transaction.php', {
        uid: currentUser.uid,
      });
      if (response.data.status === 'success') {
        setLastTransaction(response.data.lastTransaction.amount);
      } else {
        setLastTransaction('N/A');
      }
    } catch (error) {
      setLastTransaction('N/A');
    }
  }, [currentUser]);

  useEffect(() => {
    fetchBalance();
    fetchLastTransaction();

    // Polling every 10 seconds
    const intervalId = setInterval(() => {
      fetchBalance();
      fetchLastTransaction();
    }, 10000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [currentUser, fetchBalance, fetchLastTransaction]);

  const handleTopUpRequest = async ({ method, amount }) => {
    // Check if the user is active before allowing top-up
    if (userData.status !== 'Active') {
      setAlert({ show: true, message: 'Your account is not active. You cannot top up.', type: 'error' });
      return;
    }

    try {
      const response = await axios.post('https://home.homeaidrelief.com/api/tickets/add_top_up_request.php', {
        uid: currentUser.uid,
        method,
        amount,
      });
      if (response.data.status === 'success') {
        setAlert({ show: true, message: 'Your Top Up request has been submitted. You will be contacted by an administrator soon.', type: 'success' });
        setIsModalOpen(false);
      } else {
        setAlert({ show: true, message: response.data.message, type: 'error' });
      }
    } catch (error) {
      setAlert({ show: true, message: 'Error adding top up request', type: 'error' });
    }
  };

  const formatBalance = (balance) => {
    return `$${Number(balance).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  const toggleBalanceVisibility = () => {
    setIsBalanceVisible(!isBalanceVisible);
  };

  const closeAlertModal = () => {
    setAlert({ ...alert, show: false });
  };

  return (
    <section className="mb-6 w-full max-w-5xl mx-auto">
      <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800 w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-white flex items-center">
            <FaWallet className="w-6 h-6 mr-2" />
            Ticket Balance
          </h2>
          <button
            onClick={toggleBalanceVisibility}
            className="flex items-center transition duration-300 transform hover:scale-105"
            data-tip={isBalanceVisible ? 'Hide Balance' : 'Show Balance'}
          >
            {isBalanceVisible ? <EyeOffIcon className="w-6 h-6 text-white" /> : <EyeIcon className="w-6 h-6 text-white" />}
            <Tooltip place="top" type="dark" effect="solid" />
          </button>
        </div>
        <div className="flex items-center mt-4">
          <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center shadow-md mr-4">
            <FaWallet className="w-6 h-6 text-gray-700" />
          </div>
          <p className="text-3xl font-bold text-white transition duration-300 ease-in-out">
            {isBalanceVisible ? formatBalance(balance) : '****'}
          </p>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <p className="text-sm text-gray-400 flex items-center">
            <CalendarIcon className="w-6 h-6 mr-2 text-green-500" />
            Last Top Up: <span className="text-white ml-1">{lastTransaction}</span>
          </p>
          <button
            onClick={fetchBalance}
            className="flex items-center bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105"
          >
            <RefreshIcon className="w-5 h-5 mr-2" />
            Refresh
          </button>
        </div>
        <div className="mt-6 flex justify-between items-center">
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg flex items-center text-sm shadow-md transition transform hover:scale-105"
          >
            Top Up
          </button>
          <button
            onClick={() => navigate('/dashboard/transaction-history')} // Correct path
            className="flex items-center bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105"
          >
            <ClipboardListIcon className="w-5 h-5 mr-2" />
            View History
          </button>
        </div>

        <FundTicketBalanceModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleTopUpRequest}
        />

        {alert.show && (
          <CustomModal isOpen={alert.show} onClose={closeAlertModal}>
            <div className="p-6 bg-gray-800 rounded-lg shadow-lg text-white">
              <div className="flex items-center mb-4">
                {alert.type === 'success' ? (
                  <CheckCircleIcon className="w-6 h-6 mr-2 text-green-500" />
                ) : (
                  <ExclamationIcon className="w-6 h-6 mr-2 text-red-500" />
                )}
                <h2 className="text-xl font-bold">{alert.type === 'success' ? 'Success' : 'Error'}</h2>
              </div>
              <p>{alert.message}</p>
              <button
                onClick={closeAlertModal}
                className="mt-4 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105"
              >
                Close
              </button>
            </div>
          </CustomModal>
        )}
      </div>
    </section>
  );
};

export default TicketBalance;
