// src/components/Dashboard/KYCModal.js
import React from 'react';
import CustomModal from '../CustomModal';
import { CheckCircleIcon, XCircleIcon, LocationMarkerIcon } from '@heroicons/react/solid';

const KYCModal = ({ isOpen, onClose }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <h2 className="text-2xl font-bold mb-6">KYC Status Information</h2>

      <div className="mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <CheckCircleIcon className="w-6 h-6 mr-2 text-green-500" />
          Account Status
        </h3>
        <p>
          This shows whether your account is active or inactive.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <XCircleIcon className="w-6 h-6 mr-2 text-yellow-500" />
          KYC Status
        </h3>
        <p>
          Indicates if your account has been verified. A verified status means that you have successfully completed the identity verification process.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold flex items-center">
          <LocationMarkerIcon className="w-6 h-6 mr-2 text-blue-500" />
          Location
        </h3>
        <p>
          This displays your current location based on your IP address. It helps us provide location-based services and enhance security.
        </p>
      </div>

      <button
        onClick={onClose}
        className="mt-6 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105"
      >
        Close
      </button>
    </CustomModal>
  );
};

export default KYCModal;
