import React, { useState } from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import CustomModal from '../CustomModal'; // Assuming you have a modal component
import Faq from '../SettingsNavs/Faq';

const HelpSupport = () => {
  const [isHelpCenterModalOpen, setIsHelpCenterModalOpen] = useState(false);
  const [isContactSupportModalOpen, setIsContactSupportModalOpen] = useState(false);
  const [isFAQModalOpen, setIsFAQModalOpen] = useState(false);

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
        <QuestionMarkCircleIcon className="w-6 h-6 mr-2" />
        Help & Support
      </h3>
      <div className="space-y-6">
        <div>
          <label className="block text-gray-400 mb-2">Help Center</label>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md flex justify-center items-center text-sm shadow-md transition transform hover:scale-105"
            onClick={() => setIsHelpCenterModalOpen(true)}
          >
            Access
          </button>
        </div>
        <div>
          <label className="block text-gray-400 mb-2">Contact Support</label>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md flex justify-center items-center text-sm shadow-md transition transform hover:scale-105"
            onClick={() => setIsContactSupportModalOpen(true)}
          >
            Contact
          </button>
        </div>
        <div>
          <label className="block text-gray-400 mb-2">FAQ</label>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md flex justify-center items-center text-sm shadow-md transition transform hover:scale-105"
            onClick={() => setIsFAQModalOpen(true)}
          >
            View FAQ
          </button>
        </div>
      </div>

      <CustomModal isOpen={isHelpCenterModalOpen} onClose={() => setIsHelpCenterModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Help Center</h3>
          <p>Your help center content goes here...</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsHelpCenterModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isOpen={isContactSupportModalOpen} onClose={() => setIsContactSupportModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Contact Support</h3>
          <p>Your contact support content goes here...</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsContactSupportModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isOpen={isFAQModalOpen} onClose={() => setIsFAQModalOpen(false)}>
        <Faq />
        <div className="flex justify-end mt-4">
          <button
            onClick={() => setIsFAQModalOpen(false)}
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
          >
            Close
          </button>
        </div>
      </CustomModal>
    </div>
  );
};

export default HelpSupport;
