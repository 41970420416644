import React, { useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import './Loader.css';

const Loader = ({ onTimeout }) => {
  useEffect(() => {
    const timer = setTimeout(onTimeout, 3000);
    return () => clearTimeout(timer);
  }, [onTimeout]);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <div className="flex items-center justify-center mb-4">
          <FaSpinner className="loader-icon text-blue-500" />
        </div>
        <h2 className="text-2xl font-semibold text-gray-800">Processing...</h2>
        <p className="text-gray-600 mt-2">Please wait while we process your information.</p>
      </div>
    </div>
  );
};

export default Loader;
