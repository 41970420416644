import React, { useState } from 'react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-primary p-4 md:p-6 fixed top-0 left-0 w-full z-50">
      <nav className="flex justify-between items-center">
        <div className="flex items-center">
          <img src="/img.png" alt="Golden Lottery Logo" className="h-16 md:h-18" />
        </div>
        <div className="md:hidden">
          <button
            className="text-accent focus:outline-none"
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Toggle menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              )}
            </svg>
          </button>
        </div>
        <div
          className={`${
            isOpen ? 'block' : 'hidden'
          } absolute top-full left-0 right-0 bg-white md:bg-transparent md:relative md:flex md:items-center z-50 transition-all duration-300 ease-in-out md:opacity-100 md:visible`}
        >
          <ul className="md:flex md:space-x-6 p-4 md:p-0 bg-white md:bg-transparent shadow-md md:shadow-none rounded-lg md:rounded-none">
            <li>
              <a
                href="/"
                className="block py-2 px-4 text-text hover:text-accent transition duration-200"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/check-claim"
                className="block py-2 px-4 text-text hover:text-accent transition duration-200"
              >
                Check Claim
              </a>
            </li>
            <li>
              <a
                href="/past-winners"
                className="block py-2 px-4 text-text hover:text-accent transition duration-200"
              >
                Past Winners
              </a>
            </li>
            <li>
              <a
                href="/about-us"
                className="block py-2 px-4 text-text hover:text-accent transition duration-200"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="/contact-us"
                className="block py-2 px-4 text-text hover:text-accent transition duration-200"
              >
                Contact Us
              </a>
            </li>
            <li>
              <a
                href="/login"
                className="block py-2 px-4 text-text hover:text-accent transition duration-200"
              >
                Login
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
