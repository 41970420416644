import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../context/AuthProvider';
import CategorySelector from '../components/messages/CategorySelector';
import Compose from '../components/messages/Compose';
import { categories } from '../constants/categories';
import { PaperAirplaneIcon } from '@heroicons/react/solid';
import MessageHeader from '../components/messages/MessagesHeader';
import Inbox from '../components/messages/Inbox';

const Messages = () => {
  const { currentUser } = useContext(AuthContext);
  const [conversation, setConversation] = useState([]);
  const [showHelpButton, setShowHelpButton] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [isComposing, setIsComposing] = useState(false);
  const [isInbox, setIsInbox] = useState(false);

  const messageEndRef = useRef(null);
  const systemMessageSound = useRef(new Audio('/sounds/send.mp3'));

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  const handleHelpClick = () => {
    setShowHelpButton(false);
    setConversation(prev => [
      ...prev,
      { type: 'system', text: `Hi, ${currentUser && currentUser.displayName ? currentUser.displayName.split(' ')[0] : 'User'}, How can we help you today?` }
    ]);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setConversation(prev => [
      ...prev,
      { type: 'user', text: `${currentUser && currentUser.displayName ? currentUser.displayName.split(' ')[0] : 'User'}: ${category}` },
      { type: 'system', text: `You selected ${category}. Please choose an option below:` }
    ]);
  };

  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
    if (subcategory === 'Other') {
      setIsComposing(true);
    } else {
      setConversation(prev => [
        ...prev,
        { type: 'user', text: `${currentUser && currentUser.displayName ? currentUser.displayName.split(' ')[0] : 'User'}: ${subcategory}` },
        { type: 'system', text: `You selected ${subcategory}. Please describe your issue:` }
      ]);
      setIsComposing(true);
    }
  };

  const handleMessageSend = (message, attachments) => {
    const userName = currentUser && currentUser.displayName ? currentUser.displayName.split(' ')[0] : 'User';
    const newMessage = {
      type: 'user',
      text: `${userName}: ${message}`,
      status: 'Sending...',
      attachments: attachments.map((attachment, index) => ({
        name: attachment.name,
        preview: attachment.preview,
      })),
    };
    setConversation((prev) => [...prev, newMessage]);

    // Handle message sending logic here...
    setIsComposing(false);
  };

  const handleInboxClick = () => {
    setIsInbox(true);
  };

  const handleMessagesClick = () => {
    setIsInbox(false);
  };

  useEffect(() => {
    if (conversation.length > 0 && conversation[conversation.length - 1].type === 'system') {
      systemMessageSound.current.play();
    }
  }, [conversation]);

  return (
    <div className="container mx-auto p-6 -mt-20"> {/* Adjust mt-4 as needed */}
      <MessageHeader onMessagesClick={handleMessagesClick} onInboxClick={handleInboxClick} currentUser={currentUser} />
      <div className="bg-gray-800 p-6 rounded-lg shadow-md mt-16">
        {isInbox ? (
          <Inbox />
        ) : (
          <>
            {showHelpButton ? (
              <div className="text-center">
                <button
                  onClick={handleHelpClick}
                  className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md shadow-md transition transform hover:scale-105"
                >
                  I need help
                </button>
              </div>
            ) : (
              <div className="space-y-4">
                {conversation.map((message, index) => (
                  <div key={index} className={`p-3 rounded-md ${message.type === 'system' ? 'bg-gray-700 text-white text-left' : 'bg-blue-600 text-white text-right'}`}>
                    <div className="flex flex-col">
                      {message.type === 'system' ? (
                        <div className="flex items-center">
                          <PaperAirplaneIcon className="w-6 h-6 text-white mr-2" />
                          <p>Lottery Team: {message.text}</p>
                        </div>
                      ) : (
                        <div className="flex flex-col items-end">
                          <p>{message.text}</p>
                          {message.attachments && message.attachments.length > 0 && (
                            <div className="mt-2">
                              {message.attachments.map((attachment, idx) => (
                                <div key={idx} className="flex items-center">
                                  {attachment.preview && (
                                    <img src={attachment.preview} alt="Attachment Preview" className="w-16 h-16 object-cover rounded-md mr-2" />
                                  )}
                                  <span>{attachment.name}</span>
                                </div>
                              ))}
                            </div>
                          )}
                          {message.status && <span className="mt-1 text-sm">{message.status}</span>}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div ref={messageEndRef} />
                {!selectedCategory ? (
                  <CategorySelector onCategorySelect={handleCategorySelect} />
                ) : (
                  <div className="space-y-2">
                    {categories[selectedCategory].map((subcategory) => (
                      <button
                        key={subcategory}
                        onClick={() => handleSubcategorySelect(subcategory)}
                        className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md shadow-md transition transform hover:scale-105"
                      >
                        {subcategory}
                      </button>
                    ))}
                    <button
                      onClick={() => setSelectedCategory(null)}
                      className="-mt-20 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md shadow-md transition transform hover:scale-105"
                    >
                      Back
                    </button>
                  </div>
                )}
                {isComposing && (
                  <Compose onMessageSend={handleMessageSend} selectedCategory={selectedCategory} selectedSubcategory={selectedSubcategory} setConversation={setConversation} />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Messages;
