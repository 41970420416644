import React, { useEffect, useState } from 'react';
import { MailIcon, InboxIcon } from '@heroicons/react/outline';
import axios from 'axios';

const MessageHeader = ({ onMessagesClick, onInboxClick, currentUser }) => {
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      const response = await axios.get(`https://home.homeaidrelief.com/api/getUnreadCount.php?uid=${currentUser.uid}`);
      setUnreadCount(response.data.unread_count);
    };

    fetchUnreadCount();
  }, [currentUser.uid]);

  return (
    <section className="fixed top-16 left-0 w-full z-10 mb-6"> {/* Adjust top-16 to account for DashboardNavBar */}
      <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 border border-gray-800 flex justify-between items-center">
        <h2
          className="text-xl font-bold text-white flex items-center cursor-pointer"
          onClick={onMessagesClick}
        >
          <MailIcon className="w-6 h-6 mr-2" />
          Messages
        </h2>
        <button
          onClick={onInboxClick}
          className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md shadow-md transition transform hover:scale-105 flex items-center relative"
        >
          <InboxIcon className="w-5 h-5 mr-2" />
          Inbox
          {unreadCount > 0 && (
            <span className="absolute top-0 right-0 mt-1 mr-1 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
              {unreadCount}
            </span>
          )}
        </button>
      </div>
    </section>
  );
};

export default MessageHeader;
