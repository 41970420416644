// src/components/DashboardNavbar.js
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserCircleIcon, LogoutIcon, CogIcon, UserIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { AuthContext } from '../context/AuthProvider';
import { db, auth } from '../firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import CustomModal from '../components/CustomModal';
import RedeemVoucher from '../components/Voucher/RedeemVoucher';

const DashboardNavbar = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState('uploads/default-profile.png');

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const userDoc = doc(db, 'users', currentUser.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          setUserData(userSnapshot.data());
        }
      }
    };

    const fetchProfilePicture = async () => {
      if (currentUser) {
        try {
          const userDoc = doc(db, 'users', currentUser.uid);
          const userSnapshot = await getDoc(userDoc);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            if (userData.profilePicture) {
              setProfilePicture(userData.profilePicture);
            } else {
              setProfilePicture(null);
            }
          }
        } catch (error) {
          console.error('Error fetching profile picture:', error);
        }
      }
    };

    fetchUserData();
    fetchProfilePicture();
  }, [currentUser]);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const openLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };

  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNavigate = (path) => {
    
    if (path && typeof path === 'string') {
      navigate(path);
    } else {
      
    }
    setIsDropdownOpen(false);
  };

  return (
    <nav className="fixed top-0 left-0 right-0 bg-gray-800 p-4 shadow-md flex justify-between items-center z-50">
      <div className="flex items-center relative">
        <button onClick={toggleDropdown} className="relative flex items-center focus:outline-none group">
          {profilePicture ? (
            <img 
              src={profilePicture} 
              alt="User" 
              className="w-10 h-10 rounded-full mr-3 border-2 border-white hover:border-blue-500 transition duration-300 ease-in-out transform hover:scale-110 cursor-pointer"
            />
          ) : (
            <UserCircleIcon 
              className="w-10 h-10 text-white mr-3 border-2 border-white hover:border-blue-500 transition duration-300 ease-in-out transform hover:scale-110 cursor-pointer" 
            />
          )}
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
            Click to view profile
          </div>
        </button>
        <div className="text-white">
          <div className="flex items-center">
            <span className="text-xl flex items-center">
              Hi, {currentUser && currentUser.displayName ? currentUser.displayName.split(' ')[0] : 'User'}
            </span>
            <div className="relative flex items-center ml-2">
              <CheckCircleIcon className="w-5 h-5 text-green-500 animate-pulse" />
            </div>
          </div>
          {userData && (
            <span className="text-sm text-gray-300">{userData.email}</span>
          )}
        </div>
        {isDropdownOpen && (
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-48 bg-white text-black rounded-lg shadow-lg py-2 z-50 transition-transform origin-top duration-200 ease-out">
            <button
              onClick={() => handleNavigate('/dashboard/profile')}
              className="flex items-center w-full text-left px-4 py-2 hover:bg-gray-200 transition"
            >
              <UserIcon className="w-5 h-5 mr-2" />
              My Profile
            </button>
            <button
              onClick={() => handleNavigate('/dashboard/settings')}
              className="flex items-center w-full text-left px-4 py-2 hover:bg-gray-200 transition"
            >
              <CogIcon className="w-5 h-5 mr-2" />
              Settings
            </button>
            <button
              onClick={openLogoutModal}
              className="flex items-center w-full text-left px-4 py-2 hover:bg-gray-200 transition"
            >
              <LogoutIcon className="w-5 h-5 mr-2" />
              Logout
            </button>
          </div>
        )}
      </div>
      <RedeemVoucher />
      {isLogoutModalOpen && (
        <CustomModal isOpen={isLogoutModalOpen} onClose={closeLogoutModal}>
          <div className="text-white text-center">
            <h2 className="text-xl font-bold mb-4">Confirm Logout</h2>
            <p>Are you sure you want to logout?</p>
            <div className="mt-6 flex justify-center space-x-4">
              <button
                onClick={handleLogout}
                className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-lg transition duration-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Yes, Logout
              </button>
              <button
                onClick={closeLogoutModal}
                className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-lg transition duration-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
              >
                Cancel
              </button>
            </div>
          </div>
        </CustomModal>
      )}
    </nav>
  );
};

export default DashboardNavbar;
