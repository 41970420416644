import React, { useState, useEffect, useContext } from 'react';
import { CheckCircleIcon, HomeIcon } from '@heroicons/react/outline';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthProvider';

const WithdrawalConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const { state } = location;
  const { type, withdrawAmount, withdrawDate, ...details } = state || {};
  
  const [userName, setUserName] = useState('');
  const [transactionId, setTransactionId] = useState('');

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/user.php', {
          action: 'getUserData',
          uid: currentUser.uid,
        });
        const userData = response.data;
        setUserName(`${userData.first_name} ${userData.last_name}`);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserName();
  }, [currentUser]);

  useEffect(() => {
    const generateTransactionId = () => {
      const id = Math.floor(100000000 + Math.random() * 900000000).toString();
      setTransactionId(id);
    };

    generateTransactionId();
  }, []);

  const formatAmount = (amount) => {
    return parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  return (
    <section className="flex justify-center items-center -mt-4 min-h-screen p-4 bg-gray-100 overflow-auto">
      <div className="bg-white shadow-lg rounded-2xl p-4 w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl animate__animated animate__fadeInDown">
        <div className="text-center mb-6">
          <CheckCircleIcon className="w-12 h-12 text-green-500 mx-auto" />
          <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mt-4">Withdrawal Confirmation</h1>
          <p className="text-gray-500">Your withdrawal request has been successfully submitted.</p>
        </div>
        <div className="bg-gray-100 p-4 sm:p-6 rounded-lg mb-6">
          <h2 className="text-lg sm:text-xl font-semibold text-gray-700 mb-4">Withdrawal Details</h2>
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Name:</span>
            <span className="text-gray-800 font-medium">{userName}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Amount:</span>
            <span className="text-gray-800 font-medium">${formatAmount(withdrawAmount)}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Date:</span>
            <span className="text-gray-800 font-medium">{formatDate(withdrawDate)}</span>
          </div>
          {type === 'bankDeposit' ? (
            <>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600">Bank Name:</span>
                <span className="text-gray-800 font-medium">{details.bankName}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600">Account Holder:</span>
                <span className="text-gray-800 font-medium">{details.accountHolder}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600">Account Number:</span>
                <span className="text-gray-800 font-medium">{details.accountNumber}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600">Routine Number:</span>
                <span className="text-gray-800 font-medium">{details.routineNumber}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600">Bank Address:</span>
                <span className="text-gray-800 font-medium">{details.bankAddress}</span>
              </div>
              <p className="text-yellow-500 mt-4">Your bank deposit will be processed within 5-7 business days after approval.</p>
              <p className="text-red-500 mt-1">For security reasons, additional information may be required.</p>
            </>
          ) : (
            <>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600">Address:</span>
                <span className="text-gray-800 font-medium">{details.address}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600">City:</span>
                <span className="text-gray-800 font-medium">{details.city}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600">State:</span>
                <span className="text-gray-800 font-medium">{details.state}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span className="text-gray-600">Zip Code:</span>
                <span className="text-gray-800 font-medium">{details.zip}</span>
              </div>
              <p className="text-yellow-500 mt-4">A check will be mailed to your address within 1-3 business days after approval.</p>
            </>
          )}
        </div>
        <div className="bg-gray-100 p-4 sm:p-6 rounded-lg">
          <h2 className="text-lg sm:text-xl font-semibold text-gray-700 mb-4">Transaction Summary</h2>
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Transaction ID:</span>
            <span className="text-gray-800 font-medium">{transactionId}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Transaction Status:</span>
            <span className="text-gray-800 font-medium">Pending</span>
          </div>
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Processed By:</span>
            <span className="text-gray-800 font-medium">Lottery Team</span>
          </div>
        </div>
        <div className="flex justify-center mt-6">
          <button
            onClick={() => navigate('/dashboard/wallet')}
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
          >
            <HomeIcon className="w-5 h-5 mr-2" />
            Back to Wallet
          </button>
        </div>
        <div className="text-center text-gray-500 mt-6">
          <p>Thank you for using our service!</p>
          <p>If you have any questions, please contact us at <a href="mailto:support@goldenlotteryinc.com" className="text-blue-500">support@goldenlotteryinc.com</a></p>
        </div>
      </div>
    </section>
  );
};

export default WithdrawalConfirmation;
