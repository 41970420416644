import React from 'react';
import { InformationCircleIcon, UserAddIcon, UserCircleIcon, CashIcon, ShieldCheckIcon, SupportIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';

const Faq = () => {
  return (
    <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md max-h-96 overflow-y-auto">
      <h3 className="text-xl font-bold mb-4 flex items-center">
        <InformationCircleIcon className="w-6 h-6 mr-2" />
        FAQ - Golden Lottery App
      </h3>
      <div className="space-y-4">
        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <InformationCircleIcon className="w-5 h-5 mr-2" />
            General Information
          </h4>
          <p><strong>1. What is the Golden Lottery App?</strong></p>
          <p>The Golden Lottery App is a web-based platform designed to facilitate online lottery participation. Users can register, purchase tickets, check results, and manage their accounts directly through the app.</p>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <UserAddIcon className="w-5 h-5 mr-2" />
            Registration
          </h4>
          <p><strong>2. How do I register for an account?</strong></p>
          <p>To register for an account, follow these steps:</p>
          <ol className="list-decimal list-inside ml-4">
            <li>Visit the registration page.</li>
            <li>Fill out the registration form with your personal information, including first name, last name, date of birth, address, city, state, zip code, employment status, annual income, email, phone number, and create a password.</li>
            <li>Agree to the terms and conditions.</li>
            <li>Submit the form. You will receive a confirmation email upon successful registration.</li>
          </ol>
          <p><strong>3. Can I register using my Google account?</strong></p>
          <p>Yes, you can register using your Google account by clicking the "Register with Google" button on the registration page. This will allow you to sign up quickly using your Google credentials.</p>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <UserCircleIcon className="w-5 h-5 mr-2" />
            User Accounts
          </h4>
          <p><strong>4. How do I update my account information?</strong></p>
          <p>To update your account information, log in to your account and navigate to the settings page. From there, you can update your personal details, contact information, and other preferences.</p>
          <p><strong>5. How can I change my password?</strong></p>
          <p>To change your password, go to the settings page and select "Change Password." You will need to enter your current password, new password, and confirm the new password. Click "Change Password" to update your credentials.</p>
          <p><strong>6. What should I do if I forget my password?</strong></p>
          <p>If you forget your password, click the "Forgot Password" link on the login page. Enter your registered email address, and you will receive instructions on how to reset your password.</p>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <CashIcon className="w-5 h-5 mr-2" />
            Withdrawals
          </h4>
          <p><strong>7. How do I make a withdrawal?</strong></p>
          <p>To make a withdrawal, follow these steps:</p>
          <ol className="list-decimal list-inside ml-4">
            <li>Log in to your account.</li>
            <li>Navigate to the wallet section.</li>
            <li>Select the "Withdraw Funds" option.</li>
            <li>Choose your withdrawal method (bank deposit or check).</li>
            <li>Enter the required details and the amount you wish to withdraw.</li>
            <li>Confirm your withdrawal request.</li>
          </ol>
          <p><strong>8. What are the available withdrawal methods?</strong></p>
          <p>You can withdraw funds using the following methods:</p>
          <ul className="list-disc list-inside ml-4">
            <li>Bank Deposit: Provide your bank details including bank name, account holder name, account number, routine number, and bank address.</li>
            <li>Check: Provide your address, city, state, and zip code where the check will be sent.</li>
          </ul>
          <p><strong>9. How long does it take to process a withdrawal?</strong></p>
          <p>
            Bank Deposits: Typically processed within 3-5 business days after approval.
            Checks: Shipped overnight after approval.
          </p>
          <p><strong>10. Is there a minimum withdrawal amount?</strong></p>
          <p>Yes, the minimum withdrawal amount is $500.</p>
          <p><strong>11. Why can't I make a withdrawal?</strong></p>
          <p>If your account status is not "Active," you will not be able to make a withdrawal. Ensure that your account is verified and active.</p>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <CashIcon className="w-5 h-5 mr-2" />
            Withdrawal History
          </h4>
          <p><strong>12. How can I view my withdrawal history?</strong></p>
          <p>You can view your withdrawal history by navigating to the wallet section and clicking on the "Withdrawal History" button. This will display a modal with details of your past withdrawals including date, amount, and status.</p>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <ShieldCheckIcon className="w-5 h-5 mr-2" />
            Security
          </h4>
          <p><strong>13. How is my personal information protected?</strong></p>
          <p>We prioritize your privacy and security. Personal information is encrypted and stored securely. We use industry-standard security measures to protect your data from unauthorized access.</p>
          <p><strong>14. What should I do if I suspect unauthorized access to my account?</strong></p>
          <p>If you suspect unauthorized access to your account, immediately change your password and contact our support team for further assistance.</p>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <SupportIcon className="w-5 h-5 mr-2" />
            Support
          </h4>
          <p><strong>15. How can I contact customer support?</strong></p>
          <p>You can contact customer support by visiting the support page on our website. There, you will find options to reach out via email, phone, or live chat.</p>
          <p><strong>16. Where can I find more information about the terms and conditions?</strong></p>
          <p>You can find detailed terms and conditions on the terms and conditions page accessible from the footer of our website.</p>
          <p><strong>17. What if I encounter an issue while using the app?</strong></p>
          <p>If you encounter any issues while using the app, you can report them through the support page. Our team will address your concerns as promptly as possible.</p>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <QuestionMarkCircleIcon className="w-5 h-5 mr-2" />
            Miscellaneous
          </h4>
          <p><strong>18. Can I update my bank or check details?</strong></p>
          <p>Yes, you can update your bank or check details by navigating to the wallet section and selecting the "Update Bank Details" or "Update Check Details" options.</p>
          <p><strong>19. What types of notifications will I receive?</strong></p>
          <p>You will receive notifications for account activities such as successful registrations, withdrawals, and updates to your account information.</p>
          <p><strong>20. How can I delete my account?</strong></p>
          <p>To delete your account, please contact customer support. Our team will assist you in the process and ensure that your data is securely removed.</p>
        </div>
      </div>
    </div>
  );
};

export default Faq;
