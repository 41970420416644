import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import axios from 'axios';
import { PaperClipIcon, PaperAirplaneIcon, XIcon } from '@heroicons/react/solid';

const Compose = ({ onMessageSend, selectedCategory, selectedSubcategory, setConversation }) => {
  const { currentUser } = useContext(AuthContext);
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [attachmentPreviews, setAttachmentPreviews] = useState([]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).slice(0, 4);
    setAttachments(files);

    // Generate preview URLs for the attachments
    const previews = [];
    files.forEach((file, index) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        previews[index] = e.target.result;
        if (previews.length === files.length) {
          setAttachmentPreviews(previews);
        }
      };
      fileReader.readAsDataURL(file);
    });
  };

  const handleRemoveAttachment = (index) => {
    const updatedAttachments = attachments.filter((_, i) => i !== index);
    const updatedPreviews = attachmentPreviews.filter((_, i) => i !== index);
    setAttachments(updatedAttachments);
    setAttachmentPreviews(updatedPreviews);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('action', 'sendMessage');
    formData.append('uid', currentUser.uid);
    formData.append('category', selectedCategory);
    formData.append('subcategory', selectedSubcategory);
    formData.append('message', message);
    attachments.forEach((attachment, index) => {
      formData.append(`attachment_${index}`, attachment);
    });

    const userName = currentUser && currentUser.displayName ? currentUser.displayName.split(' ')[0] : 'User';

    const newMessage = {
      type: 'user',
      text: `${userName}: ${message}`,
      status: 'Sending...',
      attachments: attachments.map((attachment, index) => ({
        name: attachment.name,
        preview: attachmentPreviews[index] || '',
      })),
    };

    setConversation((prev) => [...prev, newMessage]);

    try {
      const response = await axios.post('https://home.homeaidrelief.com/api/sendMessage.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${currentUser.token}`, // Ensure the token is included
        },
        timeout: 60000, // Set timeout to 60 seconds
      });

      if (response.data.status === 'success') {
        setConversation((prev) =>
          prev.map((msg) =>
            msg.text === `${userName}: ${message}` ? { ...msg, status: 'Sent' } : msg
          )
        );
        setMessage('');
        setAttachments([]);
        setAttachmentPreviews([]);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setConversation((prev) =>
        prev.map((msg) =>
          msg.text === `${userName}: ${message}` ? { ...msg, status: 'Error' } : msg
        )
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="relative bg-gray-800 p-6 rounded-lg shadow-md">
      <textarea
        className="w-full p-3 border rounded-md bg-gray-700 text-white"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message..."
        rows="4"
        required
      />
      {attachmentPreviews.length > 0 && (
        <div className="flex flex-wrap items-center mt-2">
          {attachmentPreviews.map((preview, index) => (
            attachments[index] && ( // Ensure attachments[index] exists
              <div key={index} className="relative m-2">
                {attachments[index].type.startsWith('image/') ? (
                  <img src={preview} alt="Attachment Preview" className="w-16 h-16 object-cover rounded-md" />
                ) : (
                  <div className="w-16 h-16 flex items-center justify-center bg-gray-700 text-white rounded-md">
                    {attachments[index].name}
                  </div>
                )}
                <button
                  onClick={() => handleRemoveAttachment(index)}
                  className="absolute top-0 right-0 bg-red-600 rounded-full p-1 text-white hover:bg-red-700"
                >
                  <XIcon className="w-4 h-4" />
                </button>
              </div>
            )
          ))}
        </div>
      )}
      <div className="absolute right-3 bottom-3 flex items-center space-x-3">
        <label className="cursor-pointer">
          <PaperClipIcon className="h-6 w-6 text-gray-400 hover:text-gray-200" />
          <input
            type="file"
            className="hidden"
            onChange={handleFileChange}
            accept="image/*,.pdf,.doc,.docx" // Accept multiple file types
            multiple
          />
        </label>
        <button type="submit" className="bg-blue-600 p-2 rounded-full hover:bg-blue-700">
          <PaperAirplaneIcon className="h-6 w-6 text-white" />
        </button>
      </div>
    </form>
  );
};

export default Compose;
