import React from 'react';

const PaymentLoader = () => {
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <div className="flex items-center justify-center mb-4">
          <svg className="animate-spin h-32 w-32 text-blue-500" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12 1a11 11 0 1 0 11 11A11.013 11.013 0 0 0 12 1zm0 20a9 9 0 1 1 9-9 9.01 9.01 0 0 1-9 9z"></path>
            <path fill="currentColor" d="M19.14 12.94l-1.35 1.35a1.001 1.001 0 0 1-1.42-1.42l1.35-1.35a1.001 1.001 0 0 1 1.42 1.42zM7.21 8.53L5.86 7.18a1.001 1.001 0 0 1 1.42-1.42l1.35 1.35a1.001 1.001 0 0 1-1.42 1.42z"></path>
          </svg>
        </div>
        <h2 className="text-2xl font-semibold text-gray-800">Processing Withdraw...</h2>
        <p className="text-gray-600 mt-2">Please wait while we process your withdrawal request.</p>
      </div>
    </div>
  );
};

export default PaymentLoader;
