import React, { useState } from 'react';
import { BellIcon } from '@heroicons/react/outline';
import CustomModal from '../CustomModal'; // Assuming you have a modal component

const NotificationSettings = () => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isSMSModalOpen, setIsSMSModalOpen] = useState(false);
  const [isPushModalOpen, setIsPushModalOpen] = useState(false);

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
        <BellIcon className="w-6 h-6 mr-2" />
        Notification Settings
      </h3>
      <div className="space-y-6">
        <div>
          <label className="block text-gray-400 mb-2">Email Notifications</label>
          <div className="flex items-center">
            <input type="checkbox" className="mr-2" />
            <span className="text-gray-200">Enable</span>
            <button
              className="ml-4 bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded-md text-sm shadow-md transition transform hover:scale-105"
              onClick={() => setIsEmailModalOpen(true)}
            >
              More Info
            </button>
          </div>
        </div>
        <div>
          <label className="block text-gray-400 mb-2">SMS Notifications</label>
          <div className="flex items-center">
            <input type="checkbox" className="mr-2" />
            <span className="text-gray-200">Enable</span>
            <button
              className="ml-4 bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded-md text-sm shadow-md transition transform hover:scale-105"
              onClick={() => setIsSMSModalOpen(true)}
            >
              More Info
            </button>
          </div>
        </div>
        <div>
          <label className="block text-gray-400 mb-2">Push Notifications</label>
          <div className="flex items-center">
            <input type="checkbox" className="mr-2" />
            <span className="text-gray-200">Enable</span>
            <button
              className="ml-4 bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded-md text-sm shadow-md transition transform hover:scale-105"
              onClick={() => setIsPushModalOpen(true)}
            >
              More Info
            </button>
          </div>
        </div>
      </div>

      <CustomModal isOpen={isEmailModalOpen} onClose={() => setIsEmailModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Email Notifications</h3>
          <p>Details about email notifications go here...</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsEmailModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isOpen={isSMSModalOpen} onClose={() => setIsSMSModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">SMS Notifications</h3>
          <p>Details about SMS notifications go here...</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsSMSModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isOpen={isPushModalOpen} onClose={() => setIsPushModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Push Notifications</h3>
          <p>Details about push notifications go here...</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsPushModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default NotificationSettings;
