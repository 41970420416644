import React, { useState } from 'react';
import { CogIcon } from '@heroicons/react/outline';
import CustomModal from '../CustomModal'; // Assuming you have a modal component

const ApplicationSettings = () => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);
  const [isTimezoneModalOpen, setIsTimezoneModalOpen] = useState(false);

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
        <CogIcon className="w-6 h-6 mr-2" />
        Application Settings
      </h3>
      <div className="space-y-6">
        <div>
          <label className="block text-gray-400 mb-2">Language</label>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md flex justify-center items-center text-sm shadow-md transition transform hover:scale-105"
            onClick={() => setIsLanguageModalOpen(true)}
          >
            Select Language
          </button>
        </div>
        <div>
          <label className="block text-gray-400 mb-2">Theme</label>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md flex justify-center items-center text-sm shadow-md transition transform hover:scale-105"
            onClick={() => setIsThemeModalOpen(true)}
          >
            Select Theme
          </button>
        </div>
        <div>
          <label className="block text-gray-400 mb-2">Timezone</label>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md flex justify-center items-center text-sm shadow-md transition transform hover:scale-105"
            onClick={() => setIsTimezoneModalOpen(true)}
          >
            Select Timezone
          </button>
        </div>
      </div>

      <CustomModal isOpen={isLanguageModalOpen} onClose={() => setIsLanguageModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Select Language</h3>
          <select className="w-full p-3 border rounded-md bg-gray-800 text-gray-200">
            <option>English</option>
            <option>Spanish</option>
            <option>French</option>
            {/* Add more languages as needed */}
          </select>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsLanguageModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isOpen={isThemeModalOpen} onClose={() => setIsThemeModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Select Theme</h3>
          <select className="w-full p-3 border rounded-md bg-gray-800 text-gray-200">
            <option>Light</option>
            <option>Dark</option>
          </select>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsThemeModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isOpen={isTimezoneModalOpen} onClose={() => setIsTimezoneModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Select Timezone</h3>
          <select className="w-full p-3 border rounded-md bg-gray-800 text-gray-200">
            <option>GMT</option>
            <option>EST</option>
            <option>PST</option>
            {/* Add more timezones as needed */}
          </select>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsTimezoneModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default ApplicationSettings;
