import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthProvider';
import AnimatedModal from './AnimatedModal';
import VoucherModal from './VoucherModal';
import { Button, Input } from 'reactstrap';
import { FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa';

const RedeemVoucher = () => {
  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [code, setCode] = useState('');
  const [alert, setAlert] = useState(null);
  const [isVoucherModalOpen, setIsVoucherModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);
  const toggleVoucherModal = () => setIsVoucherModalOpen(!isVoucherModalOpen);

  const handleRedeem = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post('https://home.homeaidrelief.com/api/voucher/voucher_redeemer.php', {
        code,
        user_id: currentUser.uid,
      });

      if (response.data.status === 'success') {
        setAlert({
          type: 'success',
          message: `Voucher redeemed successfully. You have been credited $${response.data.amount}.`,
          icon: <FaCheckCircle className="text-green-500 mr-2" />,
          showBalanceButton: true,
        });
      } else {
        setAlert({
          type: 'danger',
          message: response.data.message,
          icon: <FaTimesCircle className="text-red-500 mr-2" />,
        });
      }
    } catch (error) {
      setAlert({
        type: 'danger',
        message: 'Error redeeming voucher.',
        icon: <FaTimesCircle className="text-red-500 mr-2" />,
      });
    } finally {
      setIsLoading(false);
      toggleVoucherModal();
    }
  };

  const handleCloseAll = () => {
    setIsOpen(false);
    setIsVoucherModalOpen(false);
  };

  return (
    <div className="relative">
      <Button color="primary" onClick={toggleModal} className="text-white bg-blue-600 hover:bg-blue-700 py-2 px-4 rounded-lg">
        Redeem Voucher
      </Button>
      <AnimatedModal isOpen={isOpen} onClose={toggleModal}>
        <div className="p-4 bg-gray-900 text-white rounded-lg">
          <div className="modal-header flex justify-between items-center">
            <h5 className="modal-title text-lg font-semibold">Redeem Voucher</h5>
            <button type="button" className="close text-gray-600" onClick={toggleModal}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body mt-4">
            <Input
              type="text"
              placeholder="Enter Voucher Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="w-full p-3 border rounded-md bg-gray-800 text-white"
            />
          </div>
          <div className="modal-footer mt-4 flex justify-end">
            <Button color="primary" onClick={handleRedeem} className="mr-2 bg-green-600 hover:bg-green-700" disabled={isLoading}>
              {isLoading ? <FaSpinner className="animate-spin mr-2" /> : 'Redeem'}
            </Button>
            <Button color="secondary" onClick={toggleModal} className="bg-gray-600 hover:bg-gray-700">
              Cancel
            </Button>
          </div>
        </div>
      </AnimatedModal>
      <VoucherModal isOpen={isVoucherModalOpen} toggle={handleCloseAll} alert={alert} />
    </div>
  );
};

export default RedeemVoucher;
