import React from 'react';
import PropTypes from 'prop-types';
import { categories } from '../../constants/categories';
import { ChatIcon, SupportIcon, InformationCircleIcon, ShieldCheckIcon, UserGroupIcon } from '@heroicons/react/solid';

const icons = {
  General: <InformationCircleIcon className="h-6 w-6 mr-2" />,
  Support: <SupportIcon className="h-6 w-6 mr-2" />,
  Chat: <ChatIcon className="h-6 w-6 mr-2" />,
  Security: <ShieldCheckIcon className="h-6 w-6 mr-2" />,
  Community: <UserGroupIcon className="h-6 w-6 mr-2" />,
  // Add more categories and their respective icons here
};

const CategorySelector = ({ onCategorySelect }) => {
  const handleCategoryClick = (category) => {
    onCategorySelect(category);
  };

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-md">
      <h2 className="text-xl font-bold text-white mb-4">Select a Category</h2>
      <div className="grid grid-cols-2 gap-4">
        {Object.keys(categories).map((category) => (
          <button
            key={category}
            onClick={() => handleCategoryClick(category)}
            className="flex items-center bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md shadow-md transition transform hover:scale-105"
          >
            {icons[category] || <InformationCircleIcon className="h-6 w-6 mr-2" />}
            {category}
          </button>
        ))}
      </div>
    </div>
  );
};

CategorySelector.propTypes = {
  onCategorySelect: PropTypes.func.isRequired,
};

export default CategorySelector;
