import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import FirebaseContext from '../context/FirebaseContext';
import { signInWithEmailAndPassword, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider, sendEmailVerification } from 'firebase/auth';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [error, setError] = useState(null);
  const [isResetting, setIsResetting] = useState(false);
  const { auth } = useContext(FirebaseContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (user.emailVerified) {
        navigate('/dashboard/home');
      } else {
        setError('Please verify your email address before logging in.');
        await auth.signOut();
      }
    } catch (error) {
      setError('Invalid email or password. Please try again.');
    }
  };

  const handleLoginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const googleResult = await signInWithPopup(auth, provider);
      const user = googleResult.user;
      if (user.emailVerified) {
        navigate('/dashboard/home');
      } else {
        setError('Please verify your email address before logging in.');
        await auth.signOut();
      }
    } catch (error) {
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!resetEmail) {
      setError('Please enter your email address.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setError('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setError('Failed to send reset email. Please check your email address and try again.');
    }
  };

  const handleResendVerification = async () => {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(user.user);
      setError('Verification email sent. Please check your inbox.');
    } catch (error) {
      setError('Failed to resend verification email. Please try again.');
    }
  };

  const toggleReset = () => {
    setIsResetting(!isResetting);
    setError(null);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Navbar />
      <div className="flex flex-1 flex-col items-center justify-center px-4 py-8 mt-4">
        <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
          <p className="text-center text-lg mb-6 text-gray-700">
            To access your dashboard and check your lotto status
          </p>
          <div className="flex justify-center mb-6">
            <img src="/logo.png" alt="Logo" className="h-20 w-auto" />
          </div>
          {error && <div className="text-red-500 mb-4 text-center">{error}</div>}
          {!isResetting ? (
            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                  Password
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="flex items-center justify-between mb-4">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                  type="submit"
                >
                  Sign In
                </button>
              </div>
              <div className="flex items-center justify-between mb-4">
                <button
                  className="google-signin-button"
                  type="button"
                  onClick={handleLoginWithGoogle}
                >
                  <img src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png" alt="Sign In with Google" />
                </button>
              </div>
              <div className="flex items-center justify-between mb-4">
                <button
                  className="text-blue-500 hover:text-blue-700 font-semibold hover:underline focus:outline-none w-full text-center"
                  type="button"
                  onClick={toggleReset}
                >
                  Forgot Password?
                </button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleResetPassword}>
              <h2 className="text-2xl font-bold mb-4 text-center">Reset Password</h2>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="resetEmail">
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="resetEmail"
                  type="email"
                  placeholder="Enter your email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  required
                />
              </div>
              <div className="flex items-center justify-between mb-4">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                  type="submit"
                >
                  Send Reset Email
                </button>
              </div>
              <div className="flex items-center justify-between mb-4">
                <button
                  className="text-blue-500 hover:text-blue-700 font-semibold hover:underline focus:outline-none w-full text-center"
                  type="button"
                  onClick={toggleReset}
                >
                  Back to Login
                </button>
              </div>
            </form>
          )}
          {error && error.includes('verify your email') && (
            <div className="flex items-center justify-center mt-4">
              <button
                className="text-blue-500 hover:text-blue-700 font-semibold hover:underline focus:outline-none w-full text-center"
                type="button"
                onClick={handleResendVerification}
              >
                Resend Verification Email
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
