// src/components/tickets/WonTicketModal.js
import React from 'react';
import CustomModal from '../CustomModal';
import { TicketIcon, CalendarIcon, CurrencyDollarIcon, GiftIcon, StarIcon } from '@heroicons/react/outline';

const WonTicketModal = ({ isOpen, onClose, ticket }) => {
  if (!ticket) return null;

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <div className="text-center">
        <h2 className="text-2xl font-bold text-green-500 mb-4 flex items-center justify-center">
          <TicketIcon className="w-8 h-8 mr-2" />
          Congratulations! You Have a Winning Ticket!
        </h2>
        <div className="bg-gray-700 p-4 rounded-lg shadow-md">
          <div className="text-left">
            <p className="text-lg text-white flex items-center mb-2">
              <TicketIcon className="w-5 h-5 mr-2 text-yellow-500" />
              <span>Ticket Number: <strong>{ticket.ticket_number}</strong></span>
            </p>
            <p className="text-lg text-white flex items-center mb-2">
              <CalendarIcon className="w-5 h-5 mr-2 text-blue-500" />
              <span>Draw Date: <strong>{ticket.draw_date}</strong></span>
            </p>
            <p className="text-lg text-white flex items-center mb-2">
              <CurrencyDollarIcon className="w-5 h-5 mr-2 text-green-500" />
              <span>Price: <strong>${ticket.price}</strong></span>
            </p>
            <p className="text-lg text-white flex items-center mb-2">
              <GiftIcon className="w-5 h-5 mr-2 text-purple-500" />
              <span>Prize Range: <strong>{ticket.prize_range}</strong></span>
            </p>
            <p className="text-lg text-white flex items-center mb-4">
              <StarIcon className="w-5 h-5 mr-2 text-pink-500" />
              <span>Winning Probability: <strong>{ticket.winning_probability}</strong></span>
            </p>
          </div>
        </div>
        <button
          onClick={onClose}
          className="mt-6 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105 flex items-center justify-center"
        >
          Close
        </button>
      </div>
    </CustomModal>
  );
};

export default WonTicketModal;
