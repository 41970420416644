// pages/CheckTickets.js
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardNavbar from '../components/DashboardNavbar';
import BottomNav from '../components/BottomNav';
import { AuthContext } from '../context/AuthProvider';
import axios from 'axios';
import { CheckCircleIcon, TicketIcon, ArrowLeftIcon, CalendarIcon, GiftIcon, InformationCircleIcon, CurrencyDollarIcon, AdjustmentsIcon } from '@heroicons/react/outline';
import CustomModal from '../components/CustomModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CheckTickets = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [filterDate, setFilterDate] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      if (currentUser) {
        try {
          const response = await axios.post('https://home.homeaidrelief.com/api/user.php', {
            action: 'getUserData',
            uid: currentUser.uid
          });
          if (response.data.id) {
            fetchTickets(response.data.id);
          } else {
            console.error('Error: User ID not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    const fetchTickets = async (userId) => {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/tickets/fetch_played_tickets.php', {
          user_id: userId
        });

        if (response.data.status === 'success') {
          setTickets(response.data.tickets.reverse()); // Ensuring new tickets are at the top
        } else {
          console.error(`Error: ${response.data.message}`);
        }
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchUserId();
  }, [currentUser]);

  const openTicketDetails = (ticket) => {
    setSelectedTicket(ticket);
  };

  const closeTicketDetails = () => {
    setSelectedTicket(null);
  };

  const filterTicketsByDate = () => {
    if (filterDate) {
      return tickets.filter(ticket => new Date(ticket.generation_date).toDateString() === new Date(filterDate).toDateString());
    }
    return tickets;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Won':
        return 'text-green-500';
      case 'Lost':
        return 'text-red-500';
      case 'Played':
        return 'text-yellow-500';
      default:
        return 'text-white';
    }
  };

  return (
    <div className="min-h-screen gradient-bg flex flex-col">
      <DashboardNavbar />
      <div className="flex flex-1 flex-col items-center justify-center px-4 py-8 w-full">
        <div className="w-full max-w-2xl bg-gray-800 shadow-lg rounded-2xl p-6 mb-6 border border-gray-700">
          <div className="flex items-center mb-4">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded-lg flex items-center text-sm shadow-md transition transform hover:scale-105 mr-4"
            >
              <ArrowLeftIcon className="w-5 h-5 mr-2" />
              Back
            </button>
           
            <div className="flex items-center space-x-2">
              <DatePicker
                selected={filterDate}
                onChange={(date) => setFilterDate(date)}
                className="p-2 bg-gray-700 text-white rounded-md"
                placeholderText="Filter by Date"
              />
              <AdjustmentsIcon className="w-6 h-6 text-white" />
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {filterTicketsByDate().map((ticket) => (
              <div
                key={ticket.ticket_number}
                className={`bg-gray-700 p-4 rounded-lg shadow-md flex flex-col items-center justify-center cursor-pointer ${getStatusColor(ticket.status)}`}
                onClick={() => openTicketDetails(ticket)}
              >
                <TicketIcon className="w-12 h-12 text-blue-400 mb-2" />
                <p className="text-white text-center"><strong>{ticket.ticket_number}</strong></p>
                <p className="text-gray-400 text-center">Date Generated: <small>{new Date(ticket.generation_date).toLocaleDateString()}</small></p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <BottomNav />
      {selectedTicket && (
        <CustomModal isOpen={true} onClose={closeTicketDetails}>
          <div className="text-white">
            <h2 className="text-xl font-bold mb-4 flex items-center">
              <TicketIcon className="w-6 h-6 mr-2" />
              Ticket Details
            </h2>
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <TicketIcon className="w-5 h-5 text-blue-400" />
                <p><strong>Ticket Number:</strong> {selectedTicket.ticket_number}</p>
              </div>
              <div className="flex items-center space-x-2">
                <CalendarIcon className="w-5 h-5 text-yellow-400" />
                <p><strong>Draw Date:</strong> {selectedTicket.draw_date}</p>
              </div>
              <div className="flex items-center space-x-2">
                <CurrencyDollarIcon className="w-5 h-5 text-green-400" />
                <p><strong>Price:</strong> ${selectedTicket.price.toLocaleString()}</p>
              </div>
              <div className="flex items-center space-x-2">
                <GiftIcon className="w-5 h-5 text-red-400" />
                <p><strong>Prize Range:</strong> {selectedTicket.prize_range}</p>
              </div>
              <div className="flex items-center space-x-2">
                <InformationCircleIcon className="w-5 h-5 text-purple-400" />
                <p><strong>Winning Probability:</strong> {selectedTicket.winning_probability}</p>
              </div>
              <div className={`flex items-center space-x-2 mt-4 ${getStatusColor(selectedTicket.status)}`}>
                <CheckCircleIcon className="w-5 h-5" />
                <p><strong>Status:</strong> {selectedTicket.status}</p>
              </div>
            </div>
            <button
              onClick={closeTicketDetails}
              className="mt-4 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default CheckTickets;
