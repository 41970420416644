// src/components/SettingsNavs/Privacy.js
import React from 'react';
import { ShieldCheckIcon, LockClosedIcon, InformationCircleIcon, UserCircleIcon } from '@heroicons/react/outline';

const Privacy = () => {
  return (
    <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md max-h-96 overflow-y-auto">
      <h3 className="text-xl font-bold mb-4 flex items-center">
        <ShieldCheckIcon className="w-6 h-6 mr-2" />
        Privacy Policy - Golden Lottery App
      </h3>
      <div className="space-y-4">
        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <InformationCircleIcon className="w-5 h-5 mr-2" />
            Introduction
          </h4>
          <p>We are committed to protecting your privacy. This privacy policy outlines how we collect, use, and protect your personal information.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <UserCircleIcon className="w-5 h-5 mr-2" />
            Information We Collect
          </h4>
          <p><strong>1. Personal Information:</strong> We collect personal information that you provide to us, such as your name, email address, phone number, and payment information when you register for an account or make a purchase.</p>
          <p><strong>2. Usage Data:</strong> We collect information about your interactions with the app, such as your IP address, device information, and browsing activity.</p>
          <p><strong>3. Cookies:</strong> We use cookies and similar tracking technologies to collect information about your activity on our app and hold certain information.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <LockClosedIcon className="w-5 h-5 mr-2" />
            How We Use Your Information
          </h4>
          <p><strong>1. To Provide and Maintain Our Service:</strong> We use your personal information to create and manage your account, process transactions, and provide customer support.</p>
          <p><strong>2. To Improve Our Service:</strong> We analyze usage data to understand how our app is used and to improve its functionality and user experience.</p>
          <p><strong>3. To Communicate with You:</strong> We use your contact information to send you updates, security alerts, and other information related to your use of our app.</p>
          <p><strong>4. Marketing and Advertising:</strong> We may use your information to provide you with promotional offers and other marketing materials.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <LockClosedIcon className="w-5 h-5 mr-2" />
            How We Share Your Information
          </h4>
          <p><strong>1. With Service Providers:</strong> We share your information with third-party service providers who perform services on our behalf, such as payment processing and data analysis.</p>
          <p><strong>2. For Legal Reasons:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>
          <p><strong>3. With Your Consent:</strong> We may share your information with third parties if you give us your explicit consent to do so.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <LockClosedIcon className="w-5 h-5 mr-2" />
            Data Security
          </h4>
          <p>We use industry-standard security measures to protect your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <LockClosedIcon className="w-5 h-5 mr-2" />
            Your Data Protection Rights
          </h4>
          <p><strong>1. Right to Access:</strong> You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
          <p><strong>2. Right to Rectification:</strong> You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</p>
          <p><strong>3. Right to Erasure:</strong> You have the right to request that we erase your personal data, under certain conditions.</p>
          <p><strong>4. Right to Restrict Processing:</strong> You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
          <p><strong>5. Right to Object to Processing:</strong> You have the right to object to our processing of your personal data, under certain conditions.</p>
          <p><strong>6. Right to Data Portability:</strong> You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <InformationCircleIcon className="w-5 h-5 mr-2" />
            Changes to This Privacy Policy
          </h4>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <InformationCircleIcon className="w-5 h-5 mr-2" />
            Contact Us
          </h4>
          <p>If you have any questions about this Privacy Policy, please contact us:</p>
          <p>Email: support@goldenlottery.com</p>
          <p>Address: 123 Lottery Street, Winning City, WL 45678</p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
