// utils/authUtils.js
import axios from 'axios';
import { firebaseConfig } from '../firebase-config';

export const signUpUser = async (email, password) => {

  try {
    const response = await axios.post(
      `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${firebaseConfig.apiKey}`,
      {
        email,
        password,
        returnSecureToken: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error signing up:');
    throw error;
  }
};
