import React from 'react';
import AnimatedModal from './AnimatedModal';
import { Alert, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const VoucherModal = ({ isOpen, toggle, alert }) => {
  const navigate = useNavigate();

  const handleViewBalance = () => {
    toggle();
    navigate('/dashboard/buy-new-tickets');
  };

  return (
    <AnimatedModal isOpen={isOpen} onClose={toggle}>
      {alert && (
        <div className="flex flex-col items-center">
          <Alert color={alert.type} className="flex items-center">
            {alert.icon}
            {alert.message}
          </Alert>
          {alert.showBalanceButton && (
            <Button color="primary" onClick={handleViewBalance} className="mt-4">
              View Ticket Balance
            </Button>
          )}
          <Button color="secondary" onClick={toggle} className="mt-4">
            Close
          </Button>
        </div>
      )}
    </AnimatedModal>
  );
};

export default VoucherModal;
