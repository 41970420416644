import React from 'react';
import { useSpring, animated } from 'react-spring';
import { XIcon } from '@heroicons/react/outline';

const AnimatedModal = ({ isOpen, onClose, children }) => {
  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? `translateY(0%)` : `translateY(-50%)`,
    config: { tension: 220, friction: 20 },
  });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <animated.div style={animation} className="bg-gray-800 rounded-lg shadow-lg p-6 relative w-full max-w-md md:max-w-lg lg:max-w-xl mx-4 overflow-y-auto">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-300 hover:text-gray-500 transition-colors duration-300"
        >
          <XIcon className="w-6 h-6" />
        </button>
        <div className="space-y-4 text-white">
          {children}
        </div>
      </animated.div>
    </div>
  );
};

export default AnimatedModal;
