import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthProvider';
import axios from 'axios';
import BalanceSection from '../components/Dashboard/BalanceSection';
import KYCStatusSection from '../components/Dashboard/KYCStatusSection';
import LotteryTicketsSection from '../components/Dashboard/LotteryTicketsSection';
import AnnouncementContainer from '../components/Dashboard/AnnouncementContainer';

const DashboardHome = () => {
  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState({
    firstName: '',
    email: '',
    status: '',
    balance: 0,
    tickets: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [location, setLocation] = useState('');
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const response = await axios.post('https://home.homeaidrelief.com/api/user.php', {
            action: 'getUserData',
            uid: currentUser.uid,
          });
          const userData = response.data;
          setUserData({
            ...userData,
            balance: parseFloat(userData.balance) || 0,
            tickets: userData.tickets || []
          });
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Failed to load user data. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        setLocation(response.data.country_name);
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };

    fetchLocation();
  }, []);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get('https://home.homeaidrelief.com/api/announcements/announcements.php');
        setAnnouncements(response.data.announcements);
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };

    fetchAnnouncements(); // Initial fetch
    const intervalId = setInterval(fetchAnnouncements, 5000); // Fetch every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 -mt-20"> {/* Applied negative margin-top */}
      <AnnouncementContainer announcements={announcements} />
      <BalanceSection balance={userData.balance} accountType={userData.accountType} lastTransaction={userData.lastTransaction} />
      <KYCStatusSection accountStatus={userData.accountStatus} status={userData.status} location={location} />
      <LotteryTicketsSection tickets={userData.tickets} />
    </div>
  );
};

export default DashboardHome;
