import React from 'react';
import { BellIcon, ExclamationIcon, CheckCircleIcon } from '@heroicons/react/outline';

const NotificationList = ({ notifications, onPageChange, currentPage, totalPages }) => {
  const getIcon = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircleIcon className="w-6 h-6 text-green-500 mr-2" />;
      case 'warning':
        return <ExclamationIcon className="w-6 h-6 text-yellow-500 mr-2" />;
      case 'error':
        return <ExclamationIcon className="w-6 h-6 text-red-500 mr-2" />;
      default:
        return <BellIcon className="w-6 h-6 text-blue-500 mr-2" />;
    }
  };

  return (
    <div className="p-4 bg-gray-900 rounded-lg shadow-md">
      {notifications.length > 0 ? (
        <ul className="space-y-4">
          {notifications.map((notification, index) => (
            <li key={index} className="flex items-center justify-between p-4 bg-gray-800 rounded-lg shadow-md">
              <div className="flex items-center">
                {getIcon(notification.type)}
                <div>
                  <p className="text-gray-300">{notification.message}</p>
                  {notification.date && <p className="text-gray-500 text-sm mt-1">{new Date(notification.date).toLocaleString()}</p>}
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-400">No notifications yet.</p>
      )}
      <div className="flex justify-between mt-4">
        {currentPage > 1 && (
          <button
            className="text-blue-500 hover:underline"
            onClick={() => onPageChange(currentPage - 1)}
          >
            Previous
          </button>
        )}
        {currentPage < totalPages && (
          <button
            className="text-blue-500 hover:underline"
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default NotificationList;
