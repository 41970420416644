import React, { useEffect, useState, useContext } from 'react';
import { ClockIcon, ArrowLeftIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { AuthContext } from '../../context/AuthProvider';
import CustomModal from '../CustomModal'; // Assuming you have the modal component

const WithdrawalHistory = () => {
  const { currentUser } = useContext(AuthContext);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');

  useEffect(() => {
    const fetchWithdrawalHistory = async () => {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/user.php', {
          action: 'getWithdrawalHistory',
          user_id: currentUser.id,
        });

        if (response.data.status === 'success') {
          setWithdrawalHistory(response.data.history);
          setFilteredHistory(response.data.history);
        } else {
          
        }
      } catch (error) {
        
      }
    };

    fetchWithdrawalHistory();
  }, [currentUser.id]);

  useEffect(() => {
    const filterHistory = () => {
      let filtered = withdrawalHistory;

      if (statusFilter) {
        filtered = filtered.filter(entry => entry.status === statusFilter);
      }

      if (dateFilter) {
        filtered = filtered.filter(entry => entry.date.startsWith(dateFilter));
      }

      setFilteredHistory(filtered);
    };

    filterHistory();
  }, [statusFilter, dateFilter, withdrawalHistory]);

  const statusColor = (status) => {
    switch (status) {
      case 'Approved':
        return 'text-green-500';
      case 'Pending':
        return 'text-yellow-500';
      case 'Rejected':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <section className="mb-6">
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-gradient-to-r from-blue-500 to-blue-700 shadow-lg rounded-2xl p-6 mb-6 border border-blue-800 w-full flex items-center justify-center text-white text-xl font-bold hover:bg-blue-600 transition-colors duration-300"
      >
        <ClockIcon className="w-4 h-4 mr-4" />
        Withdraw History
      </button>
      <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md max-h-96 overflow-y-auto">
          <button
            onClick={() => setIsModalOpen(false)}
            className="bg-gray-700 border border-gray-600 text-white p-2 rounded mb-4 flex items-center"
          >
            <ArrowLeftIcon className="w-5 h-5 mr-2" />
            Back
          </button>
          <div className="flex justify-between mb-4">
            <input
              type="date"
              className="bg-gray-700 border border-gray-600 text-white p-2 rounded"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
            />
            <select
              className="bg-gray-700 border border-gray-600 text-white p-2 rounded"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="">All Statuses</option>
              <option value="Approved">Approved</option>
              <option value="Pending">Pending</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>
          {filteredHistory.length > 0 ? (
            <ul>
              {filteredHistory.map((entry, index) => (
                <li key={index} className="mb-4 border-b border-gray-700 pb-4">
                  <div className="flex justify-between">
                    <div>
                      <p className="font-semibold"><span className="text-gray-400">Date:</span> {entry.date.split(' ')[0]}</p>
                      <p className="font-semibold"><span className="text-gray-400">Amount:</span> ${parseFloat(entry.amount).toFixed(2)}</p>
                      <p className={`font-semibold ${statusColor(entry.status)}`}><span className="text-gray-400">Status:</span> {entry.status}</p>
                    </div>
                    {entry.type === 'bankDeposit' ? (
                      <div>
                        <p className="font-semibold"><span className="text-gray-400">Bank Name:</span> {entry.bank_name}</p>
                        <p className="font-semibold"><span className="text-gray-400">Account Number:</span> {entry.account_number}</p>
                      </div>
                    ) : (
                      <div>
                        <p className="font-semibold"><span className="text-gray-400">Address:</span> {entry.address}</p>
                        <p className="font-semibold"><span className="text-gray-400">City:</span> {entry.city}</p>
                        <p className="font-semibold"><span className="text-gray-400">State:</span> {entry.state}</p>
                        <p className="font-semibold"><span className="text-gray-400">Zip Code:</span> {entry.zip}</p>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No withdrawals made yet.</p>
          )}
        </div>
      </CustomModal>
    </section>
  );
};

export default WithdrawalHistory;
