// src/components/wallet/AvailableBalance.js
import React, { useState } from 'react';
import { CashIcon, InformationCircleIcon, CurrencyDollarIcon, MailIcon } from '@heroicons/react/outline';
import CustomModal from '../CustomModal'; // Assuming you have a modal component

const AvailableBalance = ({ balance }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formatBalance = (balance) => {
    return balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="mb-6">
      <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800 relative">
        <h3 className="text-xl font-bold text-white flex items-center mb-4">
          <CashIcon className="w-6 h-6 mr-2" />
          Available Balance
          <button onClick={openModal} className="ml-2 p-1 rounded-full bg-gray-600 hover:bg-gray-700 transition duration-300">
            <InformationCircleIcon className="w-6 h-6 text-white" />
          </button>
        </h3>
        <div className="flex items-center mt-4">
          <p className="text-3xl font-bold text-white">${formatBalance(balance)}</p>
        </div>
        <CustomModal isOpen={isModalOpen} onClose={closeModal}>
          <h2 className="text-xl font-bold mb-4">Wallet Information</h2>
          <p>Here you can find more information about your wallet, how to make a withdrawal, and other useful tips.</p>
          <ul className="list-none mt-4 space-y-2">
            <li className="flex items-start space-x-2 p-2 bg-gray-700 rounded-lg">
              <InformationCircleIcon className="w-6 h-6 text-gray-300" />
              <span>Ensure your account is verified before making a withdrawal.</span>
            </li>
            <li className="flex items-start space-x-2 p-2 bg-gray-700 rounded-lg">
              <InformationCircleIcon className="w-6 h-6 text-gray-300" />
              <span>The minimum withdrawal amount is $500.</span>
            </li>
            <li className="flex items-start space-x-2 p-2 bg-gray-700 rounded-lg">
              <CurrencyDollarIcon className="w-6 h-6 text-gray-300" />
              <span>Withdrawals via bank are processed within 3-5 business days after approval.</span>
            </li>
            <li className="flex items-start space-x-2 p-2 bg-gray-700 rounded-lg">
              <MailIcon className="w-6 h-6 text-gray-300" />
              <span>Withdrawals via check are shipped overnight after approval.</span>
            </li>
            <li className="flex items-start space-x-2 p-2 bg-gray-700 rounded-lg">
              <a href="/support" className="flex items-center space-x-2 text-white hover:text-blue-400 transition">
                <InformationCircleIcon className="w-6 h-6 text-gray-300" />
                <span>If you encounter any issues, please contact our support team.</span>
              </a>
            </li>
          </ul>
          <button
            onClick={closeModal}
            className="mt-4 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105"
          >
            Close
          </button>
        </CustomModal>
      </div>
    </section>
  );
};

export default AvailableBalance;
