// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import PastWinners from './pages/PastWinners';
import CheckClaim from './pages/CheckClaim';
import RegistrationConfirmation from './pages/RegistrationConfirmation';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import AuthProvider from './context/AuthProvider';

const App = () => {
  return (
    <AuthProvider>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/check-claim" element={<CheckClaim />} />
          <Route path="/registration-confirmation" element={<RegistrationConfirmation />} />
          <Route path="/about-us" element={<AboutUs />} /> 
          <Route path="/past-winners" element={<PastWinners />} /> 
          <Route path="/contact-us" element={<ContactUs />} />
          <Route 
            path="/dashboard/*" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </div>
    </AuthProvider>
  );
};

export default App;
