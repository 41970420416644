// src/components/tickets/BuyTicketModal.js
import React from 'react';
import CustomModal from '../CustomModal';

const BuyTicketModal = ({ ticket, onClose, onBuy }) => {
  return (
    <CustomModal isOpen={true} onClose={onClose}>
      <h2 className="text-xl font-bold mb-4">Buy Ticket</h2>
      <p>You are about to buy a ticket for ${ticket.price}.</p>
      <p>Chance to win: {ticket.chance}</p>
      <p>Prize range: {ticket.prizeRange}</p>
      <button
        onClick={() => {
          onBuy();
          onClose();
        }}
        className="mt-4 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105"
      >
        Confirm Purchase
      </button>
    </CustomModal>
  );
};

export default BuyTicketModal;
