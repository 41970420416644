// src/components/tickets/PlayInfo.js
import React from 'react';
import { InformationCircleIcon, PlayIcon, StarIcon, GiftIcon, CheckCircleIcon } from '@heroicons/react/outline';

const PlayInfo = () => {
  return (
    <div className="mt-6 p-4 bg-gray-700 rounded-lg shadow-md">
      <h3 className="text-white text-lg font-semibold mb-4 flex items-center">
        <InformationCircleIcon className="w-6 h-6 mr-2" />
        How to Play
      </h3>
      <ul className="list-disc list-inside text-white space-y-2">
        <li className="flex items-start space-x-2">
          <PlayIcon className="w-5 h-5 text-blue-500" />
          <span>Click on the 'Play' button next to your ticket to activate it.</span>
        </li>
        <li className="flex items-start space-x-2">
          <StarIcon className="w-5 h-5 text-yellow-500" />
          <span>Each ticket has a unique number and draw date.</span>
        </li>
        <li className="flex items-start space-x-2">
          <GiftIcon className="w-5 h-5 text-green-500" />
          <span>Your chances of winning depend on the type of ticket you purchased.</span>
        </li>
        <li className="flex items-start space-x-2">
          <CheckCircleIcon className="w-5 h-5 text-pink-500" />
          <span>Check back after the draw date to see if you've won!</span>
        </li>
      </ul>
    </div>
  );
};

export default PlayInfo;
