import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider';
import { UserIcon, MailIcon } from '@heroicons/react/outline';

const ProfileSettings = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div>
      <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
        <UserIcon className="w-6 h-6 mr-2" />
        Profile Settings
      </h3>
      <div className="space-y-4">
        <div>
          <label className="block text-gray-300">First Name</label>
          <p className="p-3 border rounded-md bg-gray-800 text-gray-200">{currentUser.displayName.split(' ')[0]}</p>
        </div>
        <div>
          <label className="block text-gray-300">Last Name</label>
          <p className="p-3 border rounded-md bg-gray-800 text-gray-200">{currentUser.displayName.split(' ')[1]}</p>
        </div>
        <div>
          <label className="block text-gray-300">Email</label>
          <div className="flex items-center p-3 border rounded-md bg-gray-800 text-gray-200">
            <MailIcon className="w-5 h-5 text-gray-400 mr-2" />
            <span>{currentUser.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
