import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import './NewsModal.css';

const NewsModal = ({ isOpen, onClose, news }) => {
  if (!isOpen || !news) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-out">
      <div className="relative bg-white bg-opacity-90 rounded-lg shadow-lg p-6 max-w-sm w-full transform transition-transform duration-300 ease-out scale-100">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          <XIcon className="h-6 w-6" />
        </button>
        <div className="p-4">
          <div className="flex items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-800">{news.title}</h2>
          </div>
          <p className="text-lg text-gray-700">{news.message}</p>
        </div>
        <div className="flex justify-end mt-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition duration-200"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsModal;
