import React from 'react';
import { TicketIcon } from '@heroicons/react/outline';

const TicketBuyLoaderModal = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out">
      <div className="bg-gray-800 rounded-lg shadow-lg p-6 relative w-full max-w-md transition-transform transform scale-95 ease-in-out">
        <div className="text-white text-center">
          <h2 className="text-xl font-bold mb-4">Generating Ticket...</h2>
          <p>Please wait while we process your purchase.</p>
          <div className="flex justify-center mt-4">
            <div className="animate-spin h-12 w-12 text-blue-500">
              <TicketIcon className="h-full w-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketBuyLoaderModal;
