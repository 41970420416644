import React, { useState } from 'react';
import { ShieldCheckIcon } from '@heroicons/react/outline';
import CustomModal from '../CustomModal'; // Assuming you have a modal component

const PrivacySettings = () => {
  const [isDataSharingModalOpen, setIsDataSharingModalOpen] = useState(false);
  const [isDownloadDataModalOpen, setIsDownloadDataModalOpen] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
        <ShieldCheckIcon className="w-6 h-6 mr-2" />
        Privacy Settings
      </h3>
      <div className="space-y-6">
        <div>
          <label className="block text-gray-400 mb-2">Data Sharing</label>
          <div className="flex items-center">
            <input type="checkbox" className="mr-2" /> 
            <span className="text-gray-200">Allow sharing my data</span>
          </div>
        </div>
        <div>
          <label className="block text-gray-400 mb-2">Download Your Data</label>
          <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md flex justify-center items-center text-sm shadow-md transition transform hover:scale-105"
            onClick={() => setIsDownloadDataModalOpen(true)}
          >
            Download
          </button>
        </div>
        <div>
          <label className="block text-gray-400 mb-2">Delete Account</label>
          <button
            className="w-full bg-red-600 hover:bg-red-700 text-white py-2 rounded-md flex justify-center items-center text-sm shadow-md transition transform hover:scale-105"
            onClick={() => setIsDeleteAccountModalOpen(true)}
          >
            Delete Account
          </button>
        </div>
      </div>

      <CustomModal isOpen={isDataSharingModalOpen} onClose={() => setIsDataSharingModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Data Sharing</h3>
          <p>Details about data sharing preferences go here...</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsDataSharingModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isOpen={isDownloadDataModalOpen} onClose={() => setIsDownloadDataModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Download Your Data</h3>
          <p>Details about how to download your data go here...</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsDownloadDataModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>

      <CustomModal isOpen={isDeleteAccountModalOpen} onClose={() => setIsDeleteAccountModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Delete Account</h3>
          <p>Details about deleting your account go here...</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsDeleteAccountModalOpen(false)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default PrivacySettings;
