// src/components/Dashboard/LotteryTicketsSection.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TicketIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { FaTicketAlt, FaPlay, FaShoppingCart } from 'react-icons/fa';
import LotteryInfoModal from './LotteryInfoModal';

const LotteryTicketsSection = ({ tickets }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="mb-6">
        <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white flex items-center">
              <TicketIcon className="w-6 h-6 mr-2" />
              Lottery Tickets
            </h2>
            <InformationCircleIcon
              className="w-6 h-6 text-white transition duration-300 transform hover:scale-110 cursor-pointer"
              onClick={openModal}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {tickets.map((ticket, index) => (
              <div key={index} className="bg-gray-800 border border-gray-700 p-4 rounded-md shadow-md flex items-center">
                <TicketIcon className="w-10 h-10 text-blue-400 mr-4" />
                <div>
                  <p className="text-white"><strong>Ticket Number:</strong> {ticket.number}</p>
                  <p className="text-white"><strong>Draw Date:</strong> {ticket.drawDate}</p>
                  <p className={`text-white ${ticket.status === 'Won' ? 'text-green-500' : 'text-red-500'}`}><strong>Status:</strong> {ticket.status}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-around mt-6 space-x-4">
            <button
              className="flex flex-col items-center text-white transition transform hover:scale-105"
              onClick={() => navigate('/dashboard/check-tickets')}
            >
              <FaTicketAlt className="w-10 h-10 text-blue-500" />
              <span className="text-sm mt-1">Check Tickets</span>
            </button>
            <button
              className="flex flex-col items-center text-white transition transform hover:scale-105"
              onClick={() => navigate('/dashboard/play-tickets')}
            >
              <FaPlay className="w-10 h-10 text-green-500" />
              <span className="text-sm mt-1">Play Tickets</span>
            </button>
            <button
              className="flex flex-col items-center text-white transition transform hover:scale-105"
              onClick={() => navigate('/dashboard/buy-new-tickets')}
            >
              <FaShoppingCart className="w-10 h-10 text-purple-500" />
              <span className="text-sm mt-1">Buy New Tickets</span>
            </button>
          </div>
        </div>
      </section>
      <LotteryInfoModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default LotteryTicketsSection;
