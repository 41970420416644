import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase-config';
import { updateProfile, sendEmailVerification, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from 'firebase/auth';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CustomModal from '../components/CustomModal';
import { signUpUser } from '../utils/authUtils';

const Register = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    employmentStatus: '',
    annualIncome: '',
    email: '',
    phone: '',
    password: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (location.state?.verified) {
      setIsVerified(true);
    } else {
      navigate('/check-claim');
    }
  }, [location, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check age
    const today = new Date();
    const birthDate = new Date(formData.dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age < 18) {
      setError('You must be at least 18 years old to register.');
      window.scrollTo(0, 0);
      return;
    }

    // If modal is open, prevent default submit
    if (!isModalOpen) {
      setIsModalOpen(true);
      return;
    }

    if (!isConsentChecked) {
      setError('You must agree to the terms and conditions and confirm you are over 18 years old.');
      return;
    }

    try {
      // Sign up the user using the custom function
      const signUpResponse = await signUpUser(formData.email, formData.password);
      const { idToken, localId } = signUpResponse;

      if (!idToken || !localId) {
        throw new Error('Failed to get idToken or localId from Firebase response');
      }

      // Explicitly log in the user
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      const user = auth.currentUser;

      await updateProfile(user, {
        displayName: `${formData.firstName} ${formData.lastName}`
      });

      // Send email verification
      await sendEmailVerification(user);

      // Save user data to MySQL via PHP API
      const userData = {
        action: 'createUser',
        firebase_uid: localId,
        first_name: formData.firstName,
        last_name: formData.lastName,
        dob: formData.dob,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        employment_status: formData.employmentStatus,
        annual_income: formData.annualIncome,
        email: formData.email,
        phone: formData.phone,
        member_since: new Date().toISOString().split('T')[0], // Ensure member_since is properly formatted
        status: 'Pending',
      };


      const response = await fetch('https://home.homeaidrelief.com/api/user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}` // Pass the token for secure communication
        },
        body: JSON.stringify(userData),
      });

      const result = await response.json();
      if (result.status === 'success') {
        navigate('/registration-confirmation', { 
          state: { 
            firstName: formData.firstName, 
            status: 'Pending', 
            memberSince: new Date().toLocaleDateString() 
          } 
        });
      } else {
        setError('Failed to create user. Please try again.');
      }
    } catch (error) {
      handleFirebaseError(error);
    }
  };

  const handleFirebaseError = (error) => {
    let errorMessage = 'An error occurred. Please try again.';
    switch (error.code) {
      case 'auth/email-already-in-use':
        errorMessage = 'The email address is already in use by another account.';
        break;
      case 'auth/invalid-email':
        errorMessage = 'The email address is not valid.';
        break;
      case 'auth/operation-not-allowed':
        errorMessage = 'Email/password accounts are not enabled.';
        break;
      case 'auth/weak-password':
        errorMessage = 'The password is too weak. Please choose a stronger password.';
        break;
      default:
        errorMessage = 'An error occurred. Please try again.';
    }
    setError(errorMessage);
  };

  const handleRegisterWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const googleResult = await signInWithPopup(auth, provider);
      const user = googleResult.user;
      const uid = user.uid;

      // Save user data to MySQL via PHP API
      const response = await fetch('https://home.homeaidrelief.com/api/user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'createUser',
          firebase_uid: uid,
          first_name: user.displayName ? user.displayName.split(' ')[0] : '',
          last_name: user.displayName ? user.displayName.split(' ')[1] : '',
          dob: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          employment_status: '',
          annual_income: '',
          email: user.email,
          phone: '',
          member_since: new Date().toISOString().split('T')[0], // Ensure member_since is properly formatted
          status: 'Pending',
        }),
      });

      const googleResultJson = await response.json();
      if (googleResultJson.status === 'success') {
        navigate('/dashboard/home');
      } else {
        setError('Failed to register with Google. Please try again.');
      }
    } catch (error) {
      setError('Failed to register with Google. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Navbar />
      <div className="flex flex-1 flex-col items-center justify-center px-4 py-8">
        {isVerified ? (
          <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
            {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
            <div className="flex justify-center mb-6">
              <img src="/GetStarted2.png" alt="Logo" className="h-25 w-40" />
            </div>
            <form onSubmit={handleSubmit}>
              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">Personal Information</h2>
                <div className="mb-4">
                  <label htmlFor="firstName" className="block text-gray-700">First Name:</label>
                  <input 
                    type="text" 
                    id="firstName" 
                    name="firstName" 
                    value={formData.firstName} 
                    onChange={handleChange} 
                    required 
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="lastName" className="block text-gray-700">Last Name:</label>
                  <input 
                    type="text" 
                    id="lastName" 
                    name="lastName" 
                    value={formData.lastName} 
                    onChange={handleChange} 
                    required 
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="dob" className="block text-gray-700">Date of Birth:</label>
                  <input 
                    type="date" 
                    id="dob" 
                    name="dob" 
                    value={formData.dob} 
                    onChange={handleChange} 
                    required 
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">Address Information</h2>
                <div className="mb-4">
                  <label htmlFor="address" className="block text-gray-700">Street Address:</label>
                  <input 
                    type="text" 
                    id="address" 
                    name="address" 
                    value={formData.address} 
                    onChange={handleChange} 
                    required 
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="city" className="block text-gray-700">City:</label>
                  <input 
                    type="text" 
                    id="city" 
                    name="city" 
                    value={formData.city} 
                    onChange={handleChange} 
                    required 
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="state" className="block text-gray-700">State:</label>
                  <input 
                    type="text" 
                    id="state" 
                    name="state" 
                    value={formData.state} 
                    onChange={handleChange} 
                    required 
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="zip" className="block text-gray-700">ZIP Code:</label>
                  <input 
                    type="text" 
                    id="zip" 
                    name="zip" 
                    value={formData.zip} 
                    onChange={handleChange} 
                    required 
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">Employment Information</h2>
                <div className="mb-4">
                  <label htmlFor="employmentStatus" className="block text-gray-700">Employment Status:</label>
                  <select 
                    id="employmentStatus" 
                    name="employmentStatus" 
                    value={formData.employmentStatus} 
                    onChange={handleChange} 
                    required 
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="employed">Employed</option>
                    <option value="unemployed">Unemployed</option>
                    <option value="self-employed">Self-employed</option>
                    <option value="student">Student</option>
                    <option value="retired">Retired</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="annualIncome" className="block text-gray-700">Annual Income:</label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">$</span>
                    <input 
                      type="text" 
                      id="annualIncome" 
                      name="annualIncome" 
                      placeholder="Enter your annual income" 
                      value={formData.annualIncome} 
                      onChange={handleChange} 
                      required 
                      className="w-full pl-7 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                    />
                  </div>
                </div>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-gray-700">Email:</label>
                  <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange} 
                    required 
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="phone" className="block text-gray-700">Phone Number:</label>
                  <input 
                    type="tel" 
                    id="phone" 
                    name="phone" 
                    value={formData.phone} 
                    onChange={handleChange} 
                    required 
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="password" className="block text-gray-700">Create Password:</label>
                  <input 
                    type="password" 
                    id="password" 
                    name="password" 
                    value={formData.password} 
                    onChange={handleChange} 
                    required 
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                </div>
              </section>

              <button type="button" onClick={() => setIsModalOpen(true)} className="bg-blue-500 text-white py-2 px-4 rounded w-full hover:bg-blue-600 transition duration-300">
                Submit Application
              </button>
            </form>

            <div className="flex items-center justify-between mt-4">
              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                type="button"
                onClick={handleRegisterWithGoogle}
              >
                Register with Google
              </button>
            </div>
          </div>
        ) : (
          <div className="text-center text-red-500">
            Unauthorized access. Redirecting to Check Claim...
          </div>
        )}
      </div>
      <Footer />
      
      <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-4">Consent and Confirmation</h2>
          <p className="mb-4">By submitting this application, I hereby acknowledge and agree to comply with the <strong>Terms and Conditions</strong> outlined by Golden Lottery. I understand these terms govern my participation in the Facebook Lotto Program and that any violation may result in termination of my participation and forfeiture of any associated benefits. I affirm that all information provided in this application is accurate and complete to the best of my knowledge.</p>
          <p className="mb-4">I also confirm that I am over 18 years old.</p>
          <div className="checkbox-container mb-4">
            <input type="checkbox" id="consent" name="consent" checked={isConsentChecked} onChange={(e) => setIsConsentChecked(e.target.checked)} />
            <label htmlFor="consent" className="ml-2">I agree to the terms and conditions and confirm I am over 18 years old.</label>
          </div>
          <div className="flex justify-end mt-4">
            <button
              onClick={handleSubmit}
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              Submit Application
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105 ml-4"
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
      
    </div>
  );
};

export default Register;
