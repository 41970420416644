import React, { useState, useEffect } from 'react';
import { UserIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { FaCheckCircle, FaTimesCircle, FaMapMarkerAlt } from 'react-icons/fa';
import KYCModal from './KYCModal';
import ReactCountryFlag from 'react-country-flag';

const KYCStatusSection = ({ accountStatus, status, location }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch(`https://home.homeaidrelief.com/api/fetch_country.php`);
        const data = await response.json();
        if (data && data.country_code) {
          setCountryCode(data.country_code);
        }
      } catch (error) {
        console.error('Error fetching country code:', error);
      }
    };

    fetchCountryCode();
  }, [location]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="mb-6">
        <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white flex items-center">
              <UserIcon className="w-6 h-6 mr-2" />
              Account Information
            </h2>
            <InformationCircleIcon
              className="w-6 h-6 text-white transition duration-300 transform hover:scale-110 cursor-pointer"
              onClick={openModal}
            />
          </div>
          <div className="p-4 rounded-lg bg-gray-800">
            <p className="text-lg font-medium text-white flex items-center">
              <FaCheckCircle className="w-5 h-5 text-green-500 mr-2" />
              Account Status: <span className="text-green-500 ml-1">{status || 'Active'}</span>
            </p>
            <p className="text-lg font-medium text-white flex items-center">
              {status === 'Verified' ? (
                <FaCheckCircle className="w-5 h-5 text-green-500 mr-2" />
              ) : (
                <FaTimesCircle className="w-5 h-5 text-yellow-500 mr-2" />
              )}
              KYC Status: <span className={`${status === 'Verified' ? 'text-green-500' : 'text-yellow-500'} ml-1`}>
                {status === 'Verified' ? 'Verified' : 'Not Verified'}
              </span>
            </p>
            <p className="text-lg font-medium text-white flex items-center">
              <FaMapMarkerAlt className="w-5 h-5 text-blue-500 mr-2" />
              Location: 
              <span className="text-blue-500 ml-1 flex items-center">
                {countryCode && <ReactCountryFlag countryCode={countryCode} svg className="mr-2" />}
                {location || 'Unknown'}
              </span>
            </p>
          </div>
        </div>
      </section>
      <KYCModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default KYCStatusSection;
