import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthProvider';

const Inbox = () => {
  const { userData } = useContext(AuthContext);
  const [inboxMessages, setInboxMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const fetchInboxMessages = async () => {
      const userId = userData?.id;
      if (!userId) {
        console.error('User ID is undefined.');
        return;
      }

      try {
        const response = await axios.get(`https://home.homeaidrelief.com/api/fetchInboxMessages.php?uid=${userId}`);
        const messages = Array.isArray(response.data) ? response.data : [];
        setInboxMessages(messages);
        setUnreadCount(messages.filter(msg => msg.status === 'unread').length);
      } catch (error) {
        console.error('Error fetching inbox messages:', error);
        setInboxMessages([]);
      }
    };

    fetchInboxMessages();
  }, [userData]);

  const handleMarkAsRead = async (messageId) => {
    try {
      await axios.post('https://home.homeaidrelief.com/api/markMessageRead.php', { message_id: messageId });
      setInboxMessages((prev) =>
        prev.map((msg) =>
          msg.id === messageId ? { ...msg, status: 'read' } : msg
        )
      );
      setUnreadCount(prev => prev - 1);
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  const handleClickMessage = (message) => {
    if (message.status === 'unread') {
      handleMarkAsRead(message.id);
    }
    setSelectedMessage(message);
  };

  return (
    <div className="container mx-auto p-6 mt-0"> {/* Remove top margin */}
      <h3 className="text-white text-xl mb-4">Inbox ({unreadCount} unread)</h3>
      {selectedMessage ? (
        <div className="p-4 bg-gray-700 text-white rounded-md">
          <button onClick={() => setSelectedMessage(null)} className="mb-4 bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded">Back</button>
          <p><strong>Category:</strong> {selectedMessage.category}</p>
          <p><strong>Subcategory:</strong> {selectedMessage.subcategory}</p>
          <p><strong>Message:</strong> {selectedMessage.message}</p>
          {selectedMessage.reply && (
            <p><strong>Reply:</strong> {selectedMessage.reply}</p>
          )}
        </div>
      ) : (
        inboxMessages.length > 0 ? (
          inboxMessages.map((message) => (
            <div
              key={message.id}
              className={`p-3 rounded-md cursor-pointer ${message.status === 'unread' ? 'bg-gray-600' : 'bg-gray-700'} text-white mb-4`}
              onClick={() => handleClickMessage(message)}
            >
              <p><strong>Category:</strong> {message.category}</p>
            </div>
          ))
        ) : (
          <p className="text-white">No messages in your inbox.</p>
        )
      )}
    </div>
  );
};

export default Inbox;
