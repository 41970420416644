import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import DashboardNavbar from '../components/DashboardNavbar';
import BottomNav from '../components/BottomNav';
import { AuthContext } from '../context/AuthProvider';
import { format } from 'date-fns';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';

const TransactionHistory = () => {
  const { currentUser } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/tickets/fetch_transaction_history.php', {
          uid: currentUser.uid,
        });
        if (response.data.status === 'success') {
          setTransactions(response.data.transactions);
        } else {
          setTransactions([]);
        }
      } catch (error) {
        setTransactions([]);
      }
    };

    fetchTransactions();
  }, [currentUser]);

  const filteredTransactions = transactions.filter(transaction => {
    if (filter === 'all') return true;
    return transaction.type === filter;
  });

  const formatDate = (date) => {
    return format(new Date(date), 'MM/dd/yy');
  };

  return (
    <div className="min-h-screen gradient-bg flex flex-col">
      <DashboardNavbar />
      <div className="flex flex-1 flex-col items-center justify-center px-4 py-8 w-full">
        <div className="w-full max-w-5xl bg-gray-800 shadow-lg rounded-2xl p-6 mb-6 border border-gray-700">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded-lg flex items-center text-sm shadow-md transition transform hover:scale-105 mr-4"
            >
              <ArrowLeftIcon className="w-5 h-5 mr-2" />
              Back
            </button>
            <h3 className="text-2xl font-bold text-white">Top Up History</h3>
          </div>
          <div className="flex justify-between items-center mb-4">
            <label className="text-white">
              Filter by type:
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="ml-2 bg-gray-700 text-white rounded p-2"
              >
                <option value="all">All</option>
                <option value="top_up">Top Up</option>
                <option value="ticket_purchase">Ticket Purchase</option>
                {/* Add other types as needed */}
              </select>
            </label>
          </div>
          <div className="text-white mb-4 grid grid-cols-3 gap-4 text-center">
            <div className="font-bold">Type</div>
            <div className="font-bold">Amount</div>
            <div className="font-bold">Date</div>
          </div>
          <ul className="divide-y divide-gray-700">
            {filteredTransactions.map((transaction) => (
              <li key={transaction.id} className="py-4 grid grid-cols-3 gap-4 items-center text-white">
                <div className="text-center">
                  <p className="font-bold">{transaction.type}</p>
                </div>
                <div className="text-center">
                  <p className="font-bold">${Number(transaction.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
                <div className="text-center">
                  <p className="font-bold">{formatDate(transaction.date)}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default TransactionHistory;
