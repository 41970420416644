import React, { useState, useEffect } from 'react';
import { CreditCardIcon, CheckCircleIcon, CurrencyDollarIcon, HomeIcon, InformationCircleIcon, XIcon } from '@heroicons/react/outline';
import CustomModal from '../CustomModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WithdrawalOptions = ({
  selectedOption,
  setSelectedOption,
  handleAddAccount,
  handleAddCheckDetails,
  bankDetails,
  setBankDetails,
  checkDetails,
  setCheckDetails,
}) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [showBankForm, setShowBankForm] = useState(false);
  const [showCheckForm, setShowCheckForm] = useState(false);
  const [bankError, setBankError] = useState('');

  useEffect(() => {
    // Preserve the selected option state
    if (selectedOption === 'bankDeposit') {
      setShowBankForm(true);
      setShowCheckForm(false);
    } else if (selectedOption === 'check') {
      setShowCheckForm(true);
      setShowBankForm(false);
    }
  }, [selectedOption]);

  const openInfoModal = () => setIsInfoModalOpen(true);
  const closeInfoModal = () => setIsInfoModalOpen(false);

  const handleBankSubmit = (e) => {
    e.preventDefault();
    if (bankDetails.routineNumber.length !== 9) {
      setBankError('Kindly Provide a 9 digit Routine Number');
      toast.error('Kindly Provide a 9 digit Routine Number');
      return;
    }
    setBankError('');
    handleAddAccount(e);
    setShowBankForm(false);
  };

  const handleCheckSubmit = (e) => {
    handleAddCheckDetails(e);
    setShowCheckForm(false);
  };

  return (
    <section className="mb-6">
      <ToastContainer />
      <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-white flex items-center">
            <CreditCardIcon className="w-6 h-6 mr-2" />
            Withdrawal Options
          </h3>
          <InformationCircleIcon
            className="w-6 h-6 text-blue-400 cursor-pointer hover:text-blue-500 animate-pulse"
            onClick={openInfoModal}
          />
        </div>
        <div className="flex space-x-4 mb-4">
          <button
            onClick={() => {
              setSelectedOption('bankDeposit');
              setShowBankForm(true);
              setShowCheckForm(false);
            }}
            className={`bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105 ${selectedOption === 'bankDeposit' ? 'bg-blue-700' : ''}`}
          >
            <CurrencyDollarIcon className="w-5 h-5 mr-2" />
            Bank Deposit
          </button>
          <button
            onClick={() => {
              setSelectedOption('check');
              setShowCheckForm(true);
              setShowBankForm(false);
            }}
            className={`bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105 ${selectedOption === 'check' ? 'bg-green-700' : ''}`}
          >
            <HomeIcon className="w-5 h-5 mr-2" />
            Check
          </button>
        </div>

        <CustomModal isOpen={isInfoModalOpen} onClose={closeInfoModal}>
          <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md max-h-96 overflow-y-auto">
            <button
              onClick={closeInfoModal}
              className="bg-gray-700 border border-gray-600 text-white p-2 rounded mb-4 flex items-center"
            >
              <XIcon className="w-5 h-5 mr-2" />
              Close
            </button>
            <h3 className="text-xl font-bold mb-4 flex items-center">
              <InformationCircleIcon className="w-6 h-6 mr-2 text-blue-400" />
              Preferred Withdrawal Method
            </h3>
            <p className="mb-4">
              When choosing your withdrawal method, we highly recommend using the Check option. The Check method is our fastest and most reliable option, ensuring that your funds are delivered quickly and securely.
            </p>
            <ul className="list-disc pl-5">
              <li className="mb-2 flex items-start">
                <span className="mr-2"><CheckCircleIcon className="w-5 h-5 text-green-500" /></span>
                <span>Fast Delivery: Checks are shipped overnight, so you receive your funds the next day.</span>
              </li>
              <li className="mb-2 flex items-start">
                <span className="mr-2"><CheckCircleIcon className="w-5 h-5 text-green-500" /></span>
                <span>Secure: Checks are sent directly to your address, ensuring safe and reliable delivery.</span>
              </li>
              <li className="mb-2 flex items-start">
                <span className="mr-2"><CheckCircleIcon className="w-5 h-5 text-green-500" /></span>
                <span>Convenient: No need to wait for bank processing times; your funds are quickly accessible.</span>
              </li>
            </ul>
            <p>
              While bank deposits are also a valid option, they may take longer to process due to varying bank processing times. Choosing the Check method guarantees a swift and hassle-free experience.
            </p>
          </div>
        </CustomModal>

        {showBankForm && (
          <form onSubmit={handleBankSubmit} className="mb-6">
            <h3 className="text-xl font-bold text-white flex items-center mb-4">
              <CreditCardIcon className="w-6 h-6 mr-2" />
              Bank Deposit Details
            </h3>
            <div className="mb-4">
              <label className="block text-gray-200">Bank Name</label>
              <input
                type="text"
                value={bankDetails.bankName}
                onChange={(e) => setBankDetails({ ...bankDetails, bankName: e.target.value })}
                required
                className="w-full p-3 border rounded-md bg-gray-800 text-white"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-200">Account Holder (Must Match)</label>
              <input
                type="text"
                value={bankDetails.accountHolder}
                onChange={(e) => setBankDetails({ ...bankDetails, accountHolder: e.target.value })}
                required
                className="w-full p-3 border rounded-md bg-gray-800 text-white"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-200">Bank Account Number</label>
              <input
                type="text"
                value={bankDetails.accountNumber}
                onChange={(e) => setBankDetails({ ...bankDetails, accountNumber: e.target.value })}
                required
                className="w-full p-3 border rounded-md bg-gray-800 text-white"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-200">Bank Routine Number</label>
              <input
                type="text"
                value={bankDetails.routineNumber}
                onChange={(e) => setBankDetails({ ...bankDetails, routineNumber: e.target.value })}
                required
                pattern="\d{9}"
                className="w-full p-3 border rounded-md bg-gray-800 text-white"
              />
              {bankError && <p className="text-red-500 mt-2">{bankError}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-200">Bank Address</label>
              <input
                type="text"
                value={bankDetails.bankAddress}
                onChange={(e) => setBankDetails({ ...bankDetails, bankAddress: e.target.value })}
                required
                className="w-full p-3 border rounded-md bg-gray-800 text-white"
              />
            </div>
            <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105">
              <CheckCircleIcon className="w-5 h-5 mr-2" />
              Save Bank Details
            </button>
          </form>
        )}

        {showCheckForm && (
          <form onSubmit={handleCheckSubmit} className="mb-6">
            <h3 className="text-xl font-bold text-white flex items-center mb-4">
              <CreditCardIcon className="w-6 h-6 mr-2" />
              Check Details
            </h3>
            <div className="mb-4">
              <label className="block text-gray-200">Address</label>
              <input
                type="text"
                value={checkDetails.address}
                onChange={(e) => setCheckDetails({ ...checkDetails, address: e.target.value })}
                required
                className="w-full p-3 border rounded-md bg-gray-800 text-white"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-200">City</label>
              <input
                type="text"
                value={checkDetails.city}
                onChange={(e) => setCheckDetails({ ...checkDetails, city: e.target.value })}
                required
                className="w-full p-3 border rounded-md bg-gray-800 text-white"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-200">State</label>
              <input
                type="text"
                value={checkDetails.state}
                onChange={(e) => setCheckDetails({ ...checkDetails, state: e.target.value })}
                required
                className="w-full p-3 border rounded-md bg-gray-800 text-white"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-200">Zip Code</label>
              <input
                type="text"
                value={checkDetails.zip}
                onChange={(e) => setCheckDetails({ ...checkDetails, zip: e.target.value })}
                required
                className="w-full p-3 border rounded-md bg-gray-800 text-white"
              />
            </div>
            <button type="submit" className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105">
              <CheckCircleIcon className="w-5 h-5 mr-2" />
              Save Check Details
            </button>
          </form>
        )}
      </div>
    </section>
  );
};

export default WithdrawalOptions;
