// src/context/FirebaseContextProvider.js
import React, { useEffect, useState } from 'react';
import FirebaseContext from './FirebaseContext';
import { auth } from '../firebase-config';
import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const FirebaseContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        if (user.metadata.creationTime === user.metadata.lastSignInTime) {
          // New user, store user data in MySQL via PHP API
          const firstName = user.displayName ? user.displayName.split(' ')[0] : '';
          const lastName = user.displayName ? user.displayName.split(' ').slice(1).join(' ') : '';
          
          await fetch('/api/user.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              action: 'createUser',
              firebase_uid: user.uid,
              first_name: firstName,
              last_name: lastName,
              email: user.email,
              balance: 0,
              status: 'Active',
              tickets: [],
            }),
          });
        }
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Google sign-in error:', error);
    }
  };

  const firebaseConfig = { auth, currentUser, signInWithGoogle };

  return (
    <FirebaseContext.Provider value={firebaseConfig}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContextProvider;
