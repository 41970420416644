

import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardNavbar from '../components/DashboardNavbar';
import BottomNav from '../components/BottomNav';
import TicketOption from '../components/tickets/TicketOption';
import BuyTicketModal from '../components/tickets/BuyTicketModal';
import TicketBuyLoaderModal from '../components/tickets/TicketBuyLoaderModal';
import CustomModal from '../components/CustomModal';
import TicketBalance from '../components/tickets/TicketBalance';
import axios from 'axios';
import { AuthContext } from '../context/AuthProvider';
import { ArrowLeftIcon, CheckCircleIcon, TicketIcon, CalendarIcon, CurrencyDollarIcon, GiftIcon, StarIcon, EyeIcon, ExclamationIcon } from '@heroicons/react/outline';

const ticketOptions = [
  { price: 1000, chance: '25%', prizeRange: '$70,000 to $150,000', percentage: '25%', detail: '$1,000 gives you a 25% chance to win between $70,000 to $150,000.' },
  { price: 2000, chance: '35%', prizeRange: '$150,000 to $250,000', percentage: '35%', detail: '$2,000 gives you a 35% chance to win between $150,000 to $250,000.' },
  { price: 3000, chance: '55%', prizeRange: '$250,000 to $350,000', percentage: '45%', detail: '$3,000 gives you a 45% chance to win between $250,000 to $350,000.' },
  { price: 4000, chance: '55%', prizeRange: '$350,000 to $550,000', percentage: '55%', detail: '$4,000 gives you a 55% chance to win between $350,000 to $550,000.' },
  { price: 5000, chance: '65%', prizeRange: '$550,000 to $1,000,000', percentage: '65%', detail: '$5,000 gives you a 65% chance to win between $550,000 to $1,000,000.' },
];

const BuyNewTickets = () => {
  const navigate = useNavigate();
  const {userData } = useContext(AuthContext); // Use userData instead of currentUser directly
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [ticketBalance, setTicketBalance] = useState(0);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  useEffect(() => {
    if (userData) {
      setUserId(userData.id);
      setTicketBalance(userData.ticket_balance);
    }
  }, [userData]);

  const openModal = (ticket) => {
    setSelectedTicket(ticket);
  };

  const closeModal = () => {
    setSelectedTicket(null);
    setTicketDetails(null);
  };

  const buyTicket = async (ticket) => {
    if (!userId) {
      setAlert({ show: true, message: 'User ID not found', type: 'error' });
      return;
    }

    if (userData.status !== 'Active') {
      setAlert({ show: true, message: 'Your account is not active. You cannot top up or buy tickets.', type: 'error' });
      return;
    }

    if (userData.ticket_balance < ticket.price) {
      setAlert({ show: true, message: 'Insufficient ticket balance to buy this ticket.', type: 'error' });
      return;
    }

    setLoading(true);
    const postData = {
      user_id: userId,
      draw_date: '2024-06-16',
      price: ticket.price,
      prize_range: ticket.prizeRange,
      winning_probability: ticket.percentage
    };

    try {
      const response = await axios.post('https://home.homeaidrelief.com/api/tickets/generate_ticket.php', postData);

      if (response.data.status === 'success') {
        setTimeout(() => {
          setTicketDetails({
            ticketNumber: response.data.ticket_number,
            drawDate: '2024-12-31',
            price: ticket.price,
            prizeRange: ticket.prizeRange,
            winningProbability: ticket.percentage
          });
          setLoading(false);
        }, 5000);
      } else {
        console.error(`Error: ${response.data.message}`);
        setAlert({ show: true, message: `Error: ${response.data.message}`, type: 'error' });
        setLoading(false);
      }
    } catch (error) {
      console.error('Error generating ticket:', error);
      setAlert({ show: true, message: 'Error generating ticket. Please try again later.', type: 'error' });
      setLoading(false);
    } finally {
      closeModal();
    }
  };

  const viewTicket = () => {
    navigate('/dashboard/play-tickets');
  };

  const handleBalanceChange = (amount) => {
    setTicketBalance(ticketBalance + amount);
  };

  const closeAlertModal = () => {
    setAlert({ ...alert, show: false });
  };

  return (
    <div className="min-h-screen gradient-bg flex flex-col">
      <DashboardNavbar />
      <div className="flex flex-1 flex-col items-center justify-center px-4 py-8 w-full">
        <TicketBalance onBalanceChange={handleBalanceChange} />
        <div className="w-full max-w-5xl bg-gray-800 shadow-lg rounded-2xl p-6 mb-6 border border-gray-700">
          <div className="flex items-center mb-4">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded-lg flex items-center text-sm shadow-md transition transform hover:scale-105 mr-4"
            >
              <ArrowLeftIcon className="w-5 h-5 mr-2" />
              Back
            </button>
            <h2 className="text-2xl font-bold text-white">Buy New Tickets</h2>
          </div>
          <div className="space-y-4">
            {ticketOptions.map((ticket) => (
              <TicketOption key={ticket.price} ticket={ticket} onBuyClick={() => openModal(ticket)} />
            ))}
          </div>
        </div>
      </div>
      <BottomNav />
      {selectedTicket && (
        <BuyTicketModal ticket={selectedTicket} onClose={closeModal} onBuy={() => buyTicket(selectedTicket)} />
      )}
      {loading && <TicketBuyLoaderModal />}
      {ticketDetails && (
        <CustomModal isOpen={true} onClose={() => setTicketDetails(null)}>
          <div className="text-center">
            <h2 className="text-2xl font-bold text-green-500 mb-4 flex items-center justify-center">
              <CheckCircleIcon className="w-8 h-8 mr-2" />
              Ticket Purchased Successfully!
            </h2>
            <div className="bg-gray-700 p-4 rounded-lg shadow-md">
              <div className="text-left">
                <p className="text-lg text-white flex items-center mb-2">
                  <TicketIcon className="w-5 h-5 mr-2 text-yellow-500" />
                  <span>Ticket Number: <strong>{ticketDetails.ticketNumber}</strong></span>
                </p>
                <p className="text-lg text-white flex items-center mb-2">
                  <CalendarIcon className="w-5 h-5 mr-2 text-blue-500" />
                  <span>Draw Date: <strong>{ticketDetails.drawDate}</strong></span>
                </p>
                <p className="text-lg text-white flex items-center mb-2">
                  <CurrencyDollarIcon className="w-5 h-5 mr-2 text-green-500" />
                  <span>Price: <strong>${ticketDetails.price}</strong></span>
                </p>
                <p className="text-lg text-white flex items-center mb-2">
                  <GiftIcon className="w-5 h-5 mr-2 text-purple-500" />
                  <span>Prize Range: <strong>{ticketDetails.prizeRange}</strong></span>
                </p>
                <p className="text-lg text-white flex items-center mb-4">
                  <StarIcon className="w-5 h-5 mr-2 text-pink-500" />
                  <span>Winning Probability: <strong>{ticketDetails.winningProbability}</strong></span>
                </p>
              </div>
            </div>
            <button
              onClick={viewTicket}
              className="mt-6 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105 flex items-center justify-center"
            >
              <EyeIcon className="w-5 h-5 mr-2" />
              View Ticket
            </button>
          </div>
        </CustomModal>
      )}
      {alert.show && (
        <CustomModal isOpen={alert.show} onClose={closeAlertModal}>
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg text-white">
            <div className="flex items-center mb-4">
              {alert.type === 'success' ? (
                <CheckCircleIcon className="w-6 h-6 mr-2 text-green-500" />
              ) : (
                <ExclamationIcon className="w-6 h-6 mr-2 text-red-500" />
              )}
              <h2 className="text-xl font-bold">{alert.type === 'success' ? 'Success' : 'Error'}</h2>
            </div>
            <p>{alert.message}</p>
            <button
              onClick={closeAlertModal}
              className="mt-4 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default BuyNewTickets;
