// src/components/SettingsNavs/Terms.js
import React from 'react';
import { DocumentTextIcon, ShieldCheckIcon, UserCircleIcon, InformationCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';

const Terms = () => {
  return (
    <div className="bg-gray-800 text-white p-4 rounded-lg shadow-md max-h-96 overflow-y-auto">
      <h3 className="text-xl font-bold mb-4 flex items-center">
        <DocumentTextIcon className="w-6 h-6 mr-2" />
        Terms of Service - Golden Lottery App
      </h3>
      <div className="space-y-4">
        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <InformationCircleIcon className="w-5 h-5 mr-2" />
            Introduction
          </h4>
          <p>Welcome to the Golden Lottery App. These terms of service outline the rules and regulations for the use of our application.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <UserCircleIcon className="w-5 h-5 mr-2" />
            Account Terms
          </h4>
          <p><strong>1. User Accounts:</strong> To use certain features of the app, you must create an account. You agree to provide accurate and complete information during the registration process and to update such information to keep it accurate and complete.</p>
          <p><strong>2. Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</p>
          <p><strong>3. Account Responsibility:</strong> You are responsible for all activities conducted through your account, including any purchases or transactions. If you become aware of any unauthorized activity, you must notify us immediately.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <ShieldCheckIcon className="w-5 h-5 mr-2" />
            Privacy and Data Security
          </h4>
          <p>We prioritize your privacy and data security. Personal information collected from users is governed by our Privacy Policy. By using the app, you consent to the collection and use of your information as outlined in the Privacy Policy.</p>
          <p><strong>1. Data Collection:</strong> We collect personal information to provide and improve our services. This includes information you provide during registration, such as your name, email address, and payment information.</p>
          <p><strong>2. Data Usage:</strong> We use your data to personalize your experience, process transactions, and send notifications. We do not sell or share your personal information with third parties without your consent, except as required by law.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <InformationCircleIcon className="w-5 h-5 mr-2" />
            Usage Guidelines
          </h4>
          <p><strong>1. Prohibited Activities:</strong> You agree not to use the app for any unlawful purpose or in any way that could harm, disable, overburden, or impair the app. This includes, but is not limited to, activities such as spamming, hacking, or distributing malware.</p>
          <p><strong>2. Compliance with Laws:</strong> You agree to comply with all applicable laws and regulations in connection with your use of the app.</p>
          <p><strong>3. User Conduct:</strong> You agree not to engage in any conduct that is offensive, harmful, or otherwise inappropriate when using the app, including in communications with other users.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <ShieldCheckIcon className="w-5 h-5 mr-2" />
            Intellectual Property
          </h4>
          <p>All content, trademarks, service marks, and logos on the app are the property of Golden Lottery or its licensors. You are granted a limited, non-exclusive, non-transferable, and revocable license to access and use the app for personal, non-commercial purposes.</p>
          <p><strong>1. Content Ownership:</strong> All content, including text, graphics, images, and software, is owned by Golden Lottery or its licensors. You may not copy, modify, distribute, or create derivative works based on this content without our prior written consent.</p>
          <p><strong>2. User Content:</strong> By submitting content to the app, you grant Golden Lottery a worldwide, royalty-free, perpetual, irrevocable, and sublicensable license to use, reproduce, modify, publish, and distribute such content in any form, media, or technology.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <InformationCircleIcon className="w-5 h-5 mr-2" />
            Termination
          </h4>
          <p>We reserve the right to terminate or suspend your account and access to the app at any time, without notice, for conduct that we believe violates these terms, is harmful to other users, or is otherwise inappropriate.</p>
          <p><strong>1. Account Suspension:</strong> We may suspend or terminate your account if we suspect fraudulent activity, violation of these terms, or other conduct that we deem harmful to Golden Lottery or its users.</p>
          <p><strong>2. Effect of Termination:</strong> Upon termination, your right to use the app will immediately cease. Any provisions of these terms that by their nature should survive termination will survive, including intellectual property provisions, disclaimers, and limitations of liability.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <CheckCircleIcon className="w-5 h-5 mr-2" />
            Disclaimer of Warranties
          </h4>
          <p>The app is provided on an "as is" and "as available" basis. We do not warrant that the app will be uninterrupted, error-free, or free from viruses or other harmful components. Your use of the app is at your sole risk.</p>
          <p><strong>1. No Warranty:</strong> Golden Lottery makes no warranties or representations about the accuracy or completeness of the app's content or the content of any sites linked to the app.</p>
          <p><strong>2. Limitation of Liability:</strong> In no event shall Golden Lottery, its affiliates, or their respective directors, officers, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the app.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <InformationCircleIcon className="w-5 h-5 mr-2" />
            Changes to Terms
          </h4>
          <p>We may modify these terms at any time. If we make changes, we will provide notice through the app or by other means. Your continued use of the app after changes become effective constitutes your acceptance of the new terms.</p>
          <p><strong>1. Notification of Changes:</strong> We will notify you of any significant changes to these terms by posting a notice on the app or by other means. You are responsible for reviewing the changes and discontinuing use of the app if you do not agree to the new terms.</p>
          <p><strong>2. Continued Use:</strong> Your continued use of the app after any changes to these terms constitutes your acceptance of the new terms. If you do not agree to the changes, you must stop using the app.</p>
        </div>

        <div>
          <h4 className="text-lg font-semibold flex items-center">
            <InformationCircleIcon className="w-5 h-5 mr-2" />
            Contact Information
          </h4>
          <p>If you have any questions about these terms, please contact us at support@goldenlottery.com.</p>
          <p><strong>1. Customer Support:</strong> For any inquiries or support requests, you can reach our customer support team via email at support@goldenlottery.com. We strive to respond to all inquiries within 48 hours.</p>
          <p><strong>2. Feedback:</strong> We welcome your feedback and suggestions to improve the app. Please send your feedback to feedback@goldenlottery.com.</p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
