import React, { useState } from 'react';
import { CashIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { FaWallet } from 'react-icons/fa';
import CustomModal from '../CustomModal';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const BalanceSection = ({ balance, accountType }) => {
  const [showBalance, setShowBalance] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleBalanceVisibility = () => {
    setShowBalance(!showBalance);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formatBalance = (balance) => {
    return balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <section className="mb-6">
      <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-white flex items-center">
            <CashIcon className="w-6 h-6 mr-2" />
            Available Balance
          </h2>
          <button
            onClick={toggleBalanceVisibility}
            className="flex items-center transition duration-300 transform hover:scale-105"
            data-tip={showBalance ? 'Hide Balance' : 'Show Balance'}
          >
            {showBalance ? <EyeOffIcon className="w-5 h-5 text-white" /> : <EyeIcon className="w-5 h-5 text-white" />}
            <Tooltip place="top" type="dark" effect="solid" />
          </button>
        </div>
        <div className="flex items-center mt-4">
          <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center shadow-md mr-4">
            <svg className="w-5 h-5 text-gray-700" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2a10 10 0 100 20 10 10 0 000-20zM11 5h2v6h-2V5zm0 8h2v2h-2v-2z" />
            </svg>
          </div>
          <p className="text-2xl font-bold text-white transition duration-300 ease-in-out">
            {showBalance ? `$${formatBalance(balance)}` : '****'}
          </p>
        </div>
        <div className="mt-4">
          <p className="text-sm text-gray-400 flex items-center">
            <FaWallet className="w-5 h-5 mr-2 text-blue-500" />
            Account Type: <span className="text-white ml-1">{accountType || 'Standard'}</span>
          </p>
          
        </div>
        <div className="mt-6">
          <button
            onClick={openModal}
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg flex items-center text-sm shadow-md transition transform hover:scale-105"
          >
            View Details
          </button>
        </div>

        <CustomModal isOpen={isModalOpen} onClose={closeModal}>
          <div className="text-white">
            <h2 className="text-xl font-bold mb-4 flex items-center">
              <CashIcon className="w-6 h-6 mr-2" />
              Available Balance
            </h2>
            <p className="mb-4">The available balance is the amount of money you have in your account that is available for withdrawal or spending. It does not include any pending transactions.</p>
            <button
              onClick={closeModal}
              className="mt-4 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg shadow-md transition transform hover:scale-105"
            >
              Close
            </button>
          </div>
        </CustomModal>
      </div>
    </section>
  );
};

export default BalanceSection;
