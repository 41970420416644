import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faMapMarkerAlt, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';

const Footer = () => {
  // Initialize AOS for animations
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      {/* Prefooter Section */}
      <div className="bg-primary text-text py-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
            {/* Social Media Links */}
            <div data-aos="fade-up">
              <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
              <div className="flex justify-center md:justify-start space-x-4">
                <a href="https://facebook.com" className="text-text hover:text-accent transition duration-200">
                  <FontAwesomeIcon icon={faFacebook} className="w-6 h-6" />
                </a>
                <a href="https://twitter.com" className="text-text hover:text-accent transition duration-200">
                  <FontAwesomeIcon icon={faTwitter} className="w-6 h-6" />
                </a>
                <a href="https://instagram.com" className="text-text hover:text-accent transition duration-200">
                  <FontAwesomeIcon icon={faInstagram} className="w-6 h-6" />
                </a>
                <a href="https://linkedin.com" className="text-text hover:text-accent transition duration-200">
                  <FontAwesomeIcon icon={faLinkedin} className="w-6 h-6" />
                </a>
              </div>
            </div>
            {/* Address */}
            <div data-aos="fade-up" data-aos-delay="100">
              <h3 className="text-xl font-semibold mb-4">Our Address</h3>
              <p className="mb-2">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /> 123 Lottery Lane, Cityville, ST, 12345
              </p>
            </div>
            {/* Sitemap */}
            <div data-aos="fade-up" data-aos-delay="200">
              <h3 className="text-xl font-semibold mb-4">Sitemap</h3>
              <ul className="space-y-2">
                <li><a href="/" className="hover:text-accent transition duration-200">Home</a></li>
                <li><a href="/check-claim" className="hover:text-accent transition duration-200">Check Claim</a></li>
                <li><a href="/past-winners" className="hover:text-accent transition duration-200">Past Winners</a></li>
                <li><a href="/about-us" className="hover:text-accent transition duration-200">About Us</a></li>
                <li><a href="/contact-us" className="hover:text-accent transition duration-200">Contact Us</a></li>
                <li><a href="/login" className="hover:text-accent transition duration-200">Login</a></li>
              </ul>
            </div>
            {/* Contact */}
            <div data-aos="fade-up" data-aos-delay="300">
              <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
              <p className="mb-2">
                <FontAwesomeIcon icon={faPhone} className="mr-2" /> (123) 456-7890
              </p>
              <p className="mb-2">
                <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> support@lottery.com
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Main Footer Section */}
      <footer className="bg-secondary text-text p-4">
        <div className="container mx-auto text-center">
          © 2024 Golden Lottery. All rights reserved.
          <button
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="bg-primary text-white p-2 rounded-full ml-4 hover:bg-accent transition duration-300"
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </button>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
