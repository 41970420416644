// src/components/HeroSlider.js
import React, { useState, useEffect } from 'react';

const slides = [
  { id: 1, image: 'https://images.stockcake.com/public/0/2/5/025e8667-d06f-4ad5-8280-31b8f8342e3e_large/joyful-dance-celebration-stockcake.jpg' },
  { id: 2, image: 'https://images.stockcake.com/public/c/2/7/c27d2fee-ab35-4957-92fc-d360132381ec_large/joyful-group-hug-stockcake.jpg' },
  { id: 3, image: 'https://images.stockcake.com/public/9/c/9/9c96aa52-0348-4f00-8123-444e05283540_large/joyful-celebration-moment-stockcake.jpg' },
  { id: 4, image: 'https://images.stockcake.com/public/7/e/6/7e6cb50a-0f16-4e97-9ce6-a541e2b8a32a_large/joyful-celebration-moment-stockcake.jpg' },
  { id: 5, image: 'https://images.stockcake.com/public/e/4/f/e4f79ac0-8fac-493c-ab28-6229341f0d1d_large/joyful-autumn-day-stockcake.jpg' },
];

const HeroSlider = () => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-64 overflow-hidden">
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${index === current ? 'opacity-100' : 'opacity-0'}`}
          style={{ backgroundImage: `url(${slide.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        />
      ))}
    </div>
  );
};

export default HeroSlider;
