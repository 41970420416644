import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthProvider';
import NotificationList from '../components/notifications/NotificationList';
import axios from 'axios';

const Notifications = () => {
  const { currentUser } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const itemsPerPage = 6;

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/user.php', {
          action: 'getNotifications',
          uid: currentUser.uid,
        });

        const allNotifications = response.data.notifications || [];
        setNotifications(allNotifications);
        setTotalPages(Math.ceil(allNotifications.length / itemsPerPage));

        // Mark all notifications as read
        await axios.post('https://home.homeaidrelief.com/api/user.php', {
          action: 'markAllNotificationsRead',
          uid: currentUser.uid,
        });

        // Optionally, update the local state to mark all notifications as read
        setNotifications(allNotifications.map(notification => ({ ...notification, read: true })));
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [currentUser.uid]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedNotifications = notifications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="container mx-auto p-6 mt-20">
      <section className="mb-6">
        <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
          {loading ? (
            <p className="text-gray-400">Loading...</p>
          ) : (
            <NotificationList
              notifications={paginatedNotifications}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </section>
    </div>
  );
};

export default Notifications;
