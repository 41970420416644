import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthProvider';
import { CameraIcon, MailIcon, PhoneIcon, HomeIcon, MapIcon, BriefcaseIcon, CalendarIcon, StatusOnlineIcon, UserIcon, XIcon } from '@heroicons/react/outline';
import { storage, db } from '../firebase-config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const Profile = () => {
  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState({});
  const [profilePicture, setProfilePicture] = useState('uploads/default-profile.png');
  const [selectedFile, setSelectedFile] = useState(null);
  const [ticketsPlayed, setTicketsPlayed] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/user.php', {
          action: 'getUserData',
          uid: currentUser.uid,
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchProfilePicture = async () => {
      try {
        const userDoc = doc(db, 'users', currentUser.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          if (userData.profilePicture) {
            setProfilePicture(userData.profilePicture);
          } else {
            setProfilePicture('uploads/default-profile.png');
          }
        }
      } catch (error) {
        console.error('Error fetching profile picture:', error);
      }
    };

    const fetchTicketsPlayed = async () => {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/tickets/get_tickets_played.php', {
          uid: currentUser.uid,
        });
        if (response.data.status === 'success') {
          setTicketsPlayed(response.data.ticketsPlayed);
        } else {
          console.error('Failed to fetch tickets played:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching tickets played:', error);
      }
    };

    fetchUserData();
    fetchProfilePicture();
    fetchTicketsPlayed();
  }, [currentUser.uid]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file to upload');
      return;
    }

    const fileRef = ref(storage, `profile_pictures/${currentUser.uid}/${selectedFile.name}`);

    try {
      await uploadBytes(fileRef, selectedFile);
      const downloadURL = await getDownloadURL(fileRef);

      // Save the profile picture URL to Firestore
      const userDoc = doc(db, 'users', currentUser.uid);
      await setDoc(userDoc, { profilePicture: downloadURL }, { merge: true });

      setProfilePicture(downloadURL);
      alert('Profile picture updated successfully');
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      alert('Error uploading profile picture');
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="container mx-auto p-6 mt-12">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg relative">
        <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6 mb-6">
          <div className="w-24 h-24 md:w-32 md:h-32 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center cursor-pointer" onClick={openModal}>
            <img
              src={profilePicture}
              alt="Profile"
              className="w-full h-full object-cover"
              onError={(e) => { e.target.onerror = null; e.target.src = 'uploads/default-profile.png'; }}
            />
          </div>
          <div className="text-center md:text-left">
            <button
              onClick={() => document.getElementById('fileInput').click()}
              className="bg-blue-600 hover:bg-blue-700 text-white p-3 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105"
            >
              <CameraIcon className="w-5 h-5 mr-2" />
              Change Profile Picture
            </button>
            <input type="file" onChange={handleFileChange} className="hidden" id="fileInput" />
            {selectedFile && (
              <button
                onClick={handleUpload}
                className="bg-green-600 hover:bg-green-700 text-white p-3 rounded-md flex items-center text-sm shadow-md transition transform hover:scale-105 mt-2"
              >
                <CameraIcon className="w-5 h-5 mr-2" />
                Upload
              </button>
            )}
          </div>
        </div>
        <div className="text-white space-y-4">
          <p className="flex items-center"><UserIcon className="w-5 h-5 mr-2" /> <strong>Name:</strong> {userData.first_name} {userData.last_name}</p>
          <p className="flex items-center"><CalendarIcon className="w-5 h-5 mr-2" /> <strong>Date Of Birth:</strong> {userData.dob}</p>
          <p className="flex items-center"><HomeIcon className="w-5 h-5 mr-2" /> <strong>Current Address:</strong> {userData.address}</p>
          <p className="flex items-center"><MapIcon className="w-5 h-5 mr-2" /> <strong>City:</strong> {userData.city}</p>
          <p className="flex items-center"><MapIcon className="w-5 h-5 mr-2" /> <strong>Zip Code:</strong> {userData.zip}</p>
          <p className="flex items-center"><MapIcon className="w-5 h-5 mr-2" /> <strong>State:</strong> {userData.state}</p>
          <p className="flex items-center"><BriefcaseIcon className="w-5 h-5 mr-2" /> <strong>Employment Status:</strong> {userData.employment_status}</p>
          <p className="flex items-center"><CalendarIcon className="w-5 h-5 mr-2" /> <strong>Member Since:</strong> {userData.member_since}</p>
          <p className="flex items-center"><StatusOnlineIcon className="w-5 h-5 mr-2" /> <strong>Account Status:</strong> {userData.status}</p>
          <p className="flex items-center"><MailIcon className="w-5 h-5 mr-2" /> <strong>Email:</strong> {userData.email}</p>
          <p className="flex items-center"><PhoneIcon className="w-5 h-5 mr-2" /> <strong>Phone Number:</strong> {userData.phone}</p>
        </div>
        <div className="mt-6 text-white">
          <h3 className="text-xl font-bold">Tickets Played: {ticketsPlayed}</h3>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative">
            <button
              className="absolute top-0 right-0 m-2 text-white hover:text-gray-400"
              onClick={closeModal}
            >
              <XIcon className="w-6 h-6" />
            </button>
            <img src={profilePicture} alt="Profile" className="max-w-full max-h-full rounded-lg" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
