import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import { 
  FaFacebookF, 
  FaTwitter, 
  FaInstagram, 
  FaTelegramPlane 
} from 'react-icons/fa';

const ContactUs = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!form.name || !form.email || !form.message) {
      setError('All fields are required');
      return;
    }
    try {
      await axios.post('https://home.homeaidrelief.com/api/contact_us.php', form);
      setSubmitted(true);
    } catch (err) {
      setError('There was an error submitting the form. Please try again later.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-r from-blue-50 to-gray-50">
      <Navbar />
      <div className="flex flex-1 flex-col items-center justify-center px-4 py-4">
        <div className="bg-white shadow-2xl rounded-lg p-4 w-full max-w-2xl mb-4">
          <div className="text-center mb-4" data-aos="fade-up">
            <h1 className="text-4xl font-extrabold text-gray-800">Contact Us</h1>
            <p className="text-lg text-gray-600 leading-relaxed mt-2">
              We'd love to hear from you. Please fill out the form below and we'll get in touch with you as soon as possible.
            </p>
          </div>
          <form className="space-y-4" onSubmit={handleSubmit} data-aos="fade-up" data-aos-delay="200">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={form.name}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={form.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                rows="4"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={form.message}
                onChange={handleChange}
              ></textarea>
            </div>
            {error && <p className="text-red-600">{error}</p>}
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-transform transform hover:scale-105"
              >
                Submit
              </button>
            </div>
            {submitted && <p className="text-green-600">Thank you! Your message has been sent.</p>}
          </form>
          <div className="mt-6" data-aos="fade-up" data-aos-delay="300">
            <h2 className="text-xl font-semibold text-gray-700 text-center">Follow Us</h2>
            <div className="flex justify-center mt-4 space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                <FaFacebookF size={28} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600">
                <FaTwitter size={28} />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-pink-600 hover:text-pink-800">
                <FaInstagram size={28} />
              </a>
              <a href="https://telegram.org" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                <FaTelegramPlane size={28} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
