import React from 'react';
import ProfileSettings from '../components/settings/ProfileSettings';
import ChangePassword from '../components/settings/ChangePassword';
import NotificationSettings from '../components/settings/NotificationSettings';
import PrivacySettings from '../components/settings/PrivacySettings';
import ApplicationSettings from '../components/settings/ApplicationSettings';
import HelpSupport from '../components/settings/HelpSupport';
import AboutSettings from '../components/settings/AboutSettings';

const Settings = () => {
  return (
    <div className="container mx-auto p-6 mt-20">
      <section className="mb-6">
        <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
          <ProfileSettings />
        </div>
      </section>
      
      <section className="mb-6">
        <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
          <ChangePassword />
        </div>
      </section>
      
      <section className="mb-6">
        <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
          <NotificationSettings />
        </div>
      </section>
      
      <section className="mb-6">
        <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
          <PrivacySettings />
        </div>
      </section>
      
      <section className="mb-6">
        <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
          <ApplicationSettings />
        </div>
      </section>
      
      <section className="mb-6">
        <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
          <HelpSupport />
        </div>
      </section>
      
      <section className="mb-6">
        <div className="bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg rounded-2xl p-6 mb-6 border border-gray-800">
          <AboutSettings />
        </div>
      </section>
    </div>
  );
};

export default Settings;
