import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYahoo, faGoogle, faMicrosoft, faApple } from '@fortawesome/free-brands-svg-icons';

AOS.init();

const AboutUs = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get('https://home.homeaidrelief.com/api/testimony_generator.php');
      setTestimonials(response.data);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
    }
  };

  const sponsors = [
    { icon: faFacebook, color: 'text-blue-600' },
    { icon: faYahoo, color: 'text-purple-600' },
    { icon: faGoogle, color: 'text-red-600' },
    { icon: faMicrosoft, color: 'text-green-600' },
    { icon: faApple, color: 'text-gray-600' }
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Navbar />
      <div className="flex flex-1 flex-col items-center justify-center px-4 py-8">
        <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl mb-8">
          <section className="text-center mb-8" data-aos="fade-up">
            <h1 className="text-4xl font-bold mb-6 text-gray-800">About Us</h1>
            <p className="text-lg text-gray-600 leading-relaxed mb-6">
              Empowered with $1.7 Billion from Meta, AOL, Yahoo, Optima, and FL Studios, our mission is to provide an exciting and fair lottery experience to our users worldwide.
            </p>
            <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition-transform transform hover:scale-105">
              Learn More
            </button>
          </section>

          {/* Our Mission Section */}
          <section className="mb-8" data-aos="fade-up">
            <div className="bg-gray-100 p-6 rounded-lg shadow">
              <h2 className="text-3xl font-semibold mb-4 text-gray-700">Our Mission</h2>
              <p className="text-gray-600 leading-relaxed text-lg">
                At Golden Lottery, our mission is to provide an exciting and fair lottery experience to our users. We are committed to transparency, integrity, and ensuring that everyone has a chance to win big. Our goal is to make every user feel like a winner and to create a fun and engaging platform for all.
              </p>
            </div>
          </section>

          {/* Our Vision Section */}
          <section className="mb-8" data-aos="fade-up">
            <div className="bg-gray-100 p-6 rounded-lg shadow">
              <h2 className="text-3xl font-semibold mb-4 text-gray-700">Our Vision</h2>
              <p className="text-gray-600 leading-relaxed text-lg">
                Our vision is to revolutionize the lottery industry by leveraging cutting-edge technology and innovative ideas. We aim to become a global leader in the lottery space, known for our trustworthiness, user-centric approach, and commitment to social responsibility.
              </p>
            </div>
          </section>

          {/* Our History Section */}
          <section className="mb-8" data-aos="fade-up">
            <div className="bg-gray-100 p-6 rounded-lg shadow">
              <h2 className="text-3xl font-semibold mb-4 text-gray-700">Our History</h2>
              <p className="text-gray-600 leading-relaxed text-lg">
                Golden Lottery was founded in 2023 with the vision of creating a modern and user-friendly lottery platform. Since our inception, we have grown rapidly and expanded our services to include a wide range of lottery games, instant win games, and more. We pride ourselves on our innovation and dedication to providing the best lottery experience possible.
              </p>
            </div>
          </section>

          {/* Our Team Section */}
          <section className="mb-8" data-aos="fade-up">
            <div className="bg-gray-100 p-6 rounded-lg shadow">
              <h2 className="text-3xl font-semibold mb-4 text-gray-700">Our Team</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                <div className="text-center" data-aos="zoom-in">
                  <img
                    src="https://via.placeholder.com/150"
                    alt="Team Member"
                    className="rounded-full mx-auto mb-4 border-4 border-blue-500 hover:scale-105 transition-transform"
                  />
                  <h3 className="text-xl font-bold text-gray-800">Rick Johnson</h3>
                  <p className="text-gray-600">CEO & Founder</p>
                </div>
                <div className="text-center" data-aos="zoom-in">
                  <img
                    src="https://via.placeholder.com/150"
                    alt="Team Member"
                    className="rounded-full mx-auto mb-4 border-4 border-blue-500 hover:scale-105 transition-transform"
                  />
                  <h3 className="text-xl font-bold text-gray-800">George Chalesworth</h3>
                  <p className="text-gray-600">CTO</p>
                </div>
                <div className="text-center" data-aos="zoom-in">
                  <img
                    src="https://via.placeholder.com/150"
                    alt="Team Member"
                    className="rounded-full mx-auto mb-4 border-4 border-blue-500 hover:scale-105 transition-transform"
                  />
                  <h3 className="text-xl font-bold text-gray-800">Emily Frost</h3>
                  <p className="text-gray-600">Head of Marketing</p>
                </div>
              </div>
            </div>
          </section>

          {/* Empowerment Section */}
          <section className="mb-8" data-aos="fade-up">
            <div className="bg-gray-100 p-6 rounded-lg shadow">
              <h2 className="text-3xl font-semibold mb-4 text-gray-700">Empowerment</h2>
              <p className="text-gray-600 leading-relaxed text-lg">
                We have been empowered with $1.7 Billion from Meta, AOL, Yahoo, Optima, and FL Studios to empower people worldwide. We plan to help as many people as possible. We want to reach a global audience and ensure they have good communication with us.
              </p>
            </div>
          </section>

          {/* Testimonials Section */}
          <section className="mb-8" data-aos="fade-up">
            <div className="container mx-auto">
              <h2 className="text-3xl font-semibold mb-4 text-gray-700">Testimonials</h2>
              <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={3000}>
                {testimonials.map((testimonial, index) => (
                  <div className="px-4 py-6" key={index}>
                    <blockquote className="bg-gray-100 p-4 rounded-lg shadow">
                      <p className="text-gray-600 italic">"{testimonial.message}"</p>
                      <footer className="mt-2 text-gray-700">- {testimonial.name}</footer>
                    </blockquote>
                  </div>
                ))}
              </Carousel>
            </div>
          </section>

          {/* Sponsors Section */}
          <section className="mb-8" data-aos="fade-up">
            <div className="bg-gray-100 p-6 rounded-lg shadow">
              <h2 className="text-3xl font-semibold mb-4 text-gray-700">Our Sponsors</h2>
              <div className="sliding-container">
                <div className="sliding-icons">
                  {sponsors.map((sponsor, index) => (
                    <div key={index} className="w-full flex justify-center items-center">
                      <FontAwesomeIcon icon={sponsor.icon} className={`w-16 h-16 ${sponsor.color}`} />
                    </div>
                  ))}
                  {sponsors.map((sponsor, index) => (
                    <div key={index + sponsors.length} className="w-full flex justify-center items-center">
                      <FontAwesomeIcon icon={sponsor.icon} className={`w-16 h-16 ${sponsor.color}`} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
