import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const RegistrationConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { firstName, status, memberSince } = location.state || {};

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard/home'); // Redirect to dashboard or any other authenticated route
    } catch (error) {
      setError('Invalid email or password. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Navbar />
      <div className="flex flex-1 flex-col items-center justify-center px-4 py-8">
        <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md text-center relative">
          <i className="fa fa-check-circle-o text-green-500 mb-4" style={{ fontSize: '48px' }}></i>
          <h1 className="text-2xl font-bold mb-4 animate__animated animate__fadeIn">Congratulations, {firstName}!</h1>
          <p className="text-lg mb-4">Your account has been successfully created. Your membership status is <strong>{status}</strong>, and you've been a member since <strong>{memberSince}</strong>.</p>
          <p className="mb-4">Please login to your account.</p>
          
          {error && <p className="text-red-500 mb-4">{error}</p>}
          
          <form id="login-form" onSubmit={handleLogin} className="text-left">
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700">Email:</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                placeholder="Email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                required 
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700">Password:</label>
              <input 
                type="password" 
                id="password" 
                name="password" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)}
                required 
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
              />
            </div>
            <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full transition duration-300 ease-in-out transform hover:scale-105">Login</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RegistrationConfirmation;
