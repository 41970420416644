// src/components/tickets/FundTicketBalanceModal.js
import React, { useState } from 'react';
import { CashIcon, CreditCardIcon } from '@heroicons/react/outline';
import { FaBitcoin } from 'react-icons/fa';
import CustomModal from '../CustomModal';

const FundTicketBalanceModal = ({ isOpen, onClose, onSubmit }) => {
  const [method, setMethod] = useState('');
  const [amount, setAmount] = useState('');

  const handleMethodChange = (selectedMethod) => {
    setMethod(selectedMethod);
  };

  const handleSubmit = () => {
    if (method && amount) {
      onSubmit({ method, amount: parseFloat(amount) });
      onClose();
    }
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <div className="p-6 bg-gray-800 rounded-lg shadow-lg text-white">
        <h2 className="text-2xl font-bold mb-6 text-center">Top Up Ticket Balance</h2>
        <div className="flex justify-around mb-6">
          <button
            className={`flex items-center justify-center p-4 border-2 rounded-lg transition-colors ${method === 'Cash' ? 'bg-green-600 border-green-600 text-white' : 'bg-gray-700 border-gray-600 text-gray-300'}`}
            onClick={() => handleMethodChange('Cash')}
          >
            <CashIcon className="w-8 h-8 mr-2" />
            Cash
          </button>
          <button
            className={`flex items-center justify-center p-4 border-2 rounded-lg transition-colors ${method === 'PayPal' ? 'bg-blue-600 border-blue-600 text-white' : 'bg-gray-700 border-gray-600 text-gray-300'}`}
            onClick={() => handleMethodChange('PayPal')}
          >
            <CreditCardIcon className="w-8 h-8 mr-2" />
            PayPal
          </button>
          <button
            className={`flex items-center justify-center p-4 border-2 rounded-lg transition-colors ${method === 'Crypto' ? 'bg-yellow-500 border-yellow-500 text-white' : 'bg-gray-700 border-gray-600 text-gray-300'}`}
            onClick={() => handleMethodChange('Crypto')}
          >
            <FaBitcoin className="w-8 h-8 mr-2" />
            Crypto
          </button>
        </div>
        <div className="mb-6">
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="p-4 bg-gray-700 text-white rounded-md w-full text-lg"
            placeholder="Enter amount"
          />
        </div>
        <button
          onClick={handleSubmit}
          className="bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-lg shadow-md transition transform hover:scale-105 w-full text-lg font-semibold"
        >
          Submit
        </button>
      </div>
    </CustomModal>
  );
};

export default FundTicketBalanceModal;
