import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardNavbar from '../components/DashboardNavbar';
import BottomNav from '../components/BottomNav';
import { AuthContext } from '../context/AuthProvider';
import axios from 'axios';
import { CheckCircleIcon, ArrowLeftIcon, PlayIcon } from '@heroicons/react/outline';
import CustomModal from '../components/CustomModal';
import PlayInfo from '../components/tickets/PlayInfo';

const PlayTickets = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserId = async () => {
      if (currentUser) {
        try {
          const response = await axios.post('https://home.homeaidrelief.com/api/user.php', {
            action: 'getUserData',
            uid: currentUser.uid
          });
          if (response.data.id) {
            fetchTickets(response.data.id);
          } else {
            console.error('Error: User ID not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    const fetchTickets = async (userId) => {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/tickets/fetch_tickets.php', {
          user_id: userId
        });
    
        if (response.data.status === 'success') {
          // Sort tickets by purchase_time in descending order
          const sortedTickets = response.data.tickets.sort((a, b) => {
            const dateA = new Date(a.purchase_time);
            const dateB = new Date(b.purchase_time);
            
            return dateB - dateA;
          });
    
          setTickets(sortedTickets);
        } else {
          console.error(`Error: ${response.data.message}`);
        }
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };
    

    fetchUserId();
  }, [currentUser]);

  const playTicket = async (ticketNumber) => {
    setLoading(true);
    try {
      const response = await axios.post('https://home.homeaidrelief.com/api/tickets/play_ticket.php', {
        ticket_number: ticketNumber
      });

      if (response.data.status === 'success') {
        setTimeout(() => {
          setTickets(tickets.map(ticket => ticket.ticket_number === ticketNumber ? { ...ticket, status: 'Played' } : ticket));
          setLoading(false);
        }, 2000); // Delay of 2 seconds
      } else {
        console.error(`Error: ${response.data.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error playing ticket:', error);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen gradient-bg flex flex-col">
      <DashboardNavbar />
      <div className="flex flex-1 flex-col items-center justify-center px-4 py-8 w-full">
        <div className="w-full max-w-2xl bg-gray-800 shadow-lg rounded-2xl p-6 mb-6 border border-gray-700">
          <PlayInfo /> {/* Include PlayInfo component at the top */}
          <div className="flex items-center mb-4 mt-6">
            <button
              onClick={() => navigate(-1)}
              className="bg-gray-700 hover:bg-gray-600 text-white py-2 px-4 rounded-lg flex items-center text-sm shadow-md transition transform hover:scale-105 mr-4"
            >
              <ArrowLeftIcon className="w-5 h-5 mr-2" />
              Back
            </button>
            <h2 className="text-2xl font-bold text-white">Play Tickets</h2>
          </div>
          <div className="space-y-4">
            {tickets.map((ticket) => (
              <div key={ticket.ticket_number} className="bg-gray-700 p-4 rounded-lg shadow-md flex justify-between items-center">
                <div>
                  <p className="text-white"><strong>Ticket Number:</strong> {ticket.ticket_number}</p>
                  <p className="text-white"><strong>Draw Date:</strong> {ticket.draw_date}</p>
                  <p className={`text-white ${ticket.status === 'Won' ? 'text-green-500' : ticket.status === 'Lost' ? 'text-red-500' : 'text-yellow-500'}`}><strong>Status:</strong> {ticket.status}</p>
                </div>
                {ticket.status === 'Pending' && (
                  <button
                    onClick={() => playTicket(ticket.ticket_number)}
                    className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-lg flex items-center text-sm shadow-md transition transform hover:scale-105"
                  >
                    <PlayIcon className="w-5 h-5 mr-2" />
                    Play
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <BottomNav />
      {loading && (
        <CustomModal isOpen={true} onClose={() => {}}>
          <div className="text-center">
            <h2 className="text-xl font-bold text-green-500 mb-4 flex items-center justify-center">
              <CheckCircleIcon className="w-8 h-8 mr-2" />
              Playing Ticket...
            </h2>
            <p>Please wait while we process your request.</p>
            <div className="loader mt-4"></div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default PlayTickets;
