import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeIcon, CashIcon, BellIcon, ChatAlt2Icon } from '@heroicons/react/outline';
import axios from 'axios';
import { AuthContext } from '../context/AuthProvider';

const BottomNav = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const fetchNotificationCount = async () => {
      try {
        const response = await axios.post('https://home.homeaidrelief.com/api/user.php', {
          action: 'getNotifications',
          uid: currentUser.uid,
        });

        const notifications = response.data.notifications;
        const recentNotifications = notifications.filter(notification => {
          const notificationDate = new Date(notification.date);
          const currentDate = new Date();
          const differenceInDays = (currentDate - notificationDate) / (1000 * 3600 * 24);
          return differenceInDays <= 7;
        });

        setNotificationCount(recentNotifications.length);
      } catch (error) {
        
      }
    };

    fetchNotificationCount();
  }, [currentUser.uid]);

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg flex justify-around py-3 border-t border-gray-200 z-50">
      <button 
        onClick={() => navigate('/dashboard/home')} 
        className="flex flex-col items-center transition duration-200 hover:text-blue-500 focus:outline-none"
      >
        <HomeIcon className="w-7 h-7 text-blue-500 mb-1" />
        <span className="text-xs font-medium text-blue-500">Home</span>
      </button>
      <button 
        onClick={() => navigate('/dashboard/wallet')} 
        className="flex flex-col items-center transition duration-200 hover:text-green-500 focus:outline-none"
      >
        <CashIcon className="w-7 h-7 text-green-500 mb-1" />
        <span className="text-xs font-medium text-green-500">Wallet</span>
      </button>
      <button 
        onClick={() => navigate('/dashboard/messages')} 
        className="flex flex-col items-center transition duration-200 hover:text-purple-500 focus:outline-none"
      >
        <ChatAlt2Icon className="w-7 h-7 text-purple-500 mb-1" />
        <span className="text-xs font-medium text-purple-500">Messages</span>
      </button>

      <button 
        onClick={() => navigate('/dashboard/notifications')} 
        className="relative flex flex-col items-center transition duration-200 hover:text-red-500 focus:outline-none"
      >
        <BellIcon className="w-7 h-7 text-red-500 mb-1" />
        <span className="text-xs font-medium text-red-500">Notifications</span>
        {notificationCount > 0 && (
          <span className="absolute top-0 right-0 mt-1 mr-1 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center animate-bounce">
            {notificationCount}
          </span>
        )}
      </button>
    </div>
  );
};

export default BottomNav;
